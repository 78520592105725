<template>
  <v-container fluid>
    <v-row dense>
      <!-- Primeira Coluna -->
      <v-col cols="2">
        <h2><b>Dados Gerais</b></h2>
        <p><b>Data de Início:</b> {{ formatDate(item.data_inicio) }}</p>
        <p><b>Data de Fim:</b> {{ item.data_fim ? formatDate(item.data_fim) : 'Indeterminado' }}</p>
      </v-col>

      <!-- Terceira Coluna -->
      <v-col cols="3">
        <v-col v-if="!item.id_medicamento_referencia_complementar">
          <h3><b>Frequência e Administração</b></h3>
          <p><b>Frequência:</b> {{ item.frequencia?.nome || 'Não especificado' }}</p>
          <p v-if="item.quantidade_tratamento"><b>Quantidade de Administração:</b> {{ item.quantidade_tratamento }}</p>
          <p v-if="item.dias_tratamento"><b>Dias de Tratamento:</b> {{ item.dias_tratamento }}</p>

          <p><b>Dias da Semana:</b>
            <span v-if="item.dias_semana?.length">
              {{ item.dias_semana.map(dia => dia.nome).join(', ') }}
            </span>
            <span v-else>Não especificado</span>
          </p>

          <p><b>Horários de Administração:</b>
            <span v-if="item.horario_administracao?.length">
              {{ item.horario_administracao.map(horario => horario.nome).join(', ') }}
            </span>
            <span v-else>Não especificado</span>
          </p>
        </v-col>
      </v-col>

      <v-col cols="3">
        <p><b>Observações / Instruções:</b> {{ item.obs || 'Nenhuma' }}</p>
        <p><b>Quem Adicionou essa Medicação:</b> {{ item.quem_prescreveu || 'Não Informado' }}</p>
        <p v-if="item.quem_modificou"><b>Quem Modificou essa Medicação:</b> {{ item.quem_modificou || 'Não Informado' }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "IndexRevisado",
  props: {
    item: Object,
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return 'Não informado';
      const [year, month, day] = dateString.split('-');
      return `${day}/${month}/${year}`;
    }
  }
};
</script>

<style scoped>
.text {
  color: red;
}
</style>
