<template>
  <div class="px-4 pb-4">
    <v-row dense>
      <v-col>
        <v-data-table :loading="!isLoaded && isLoading('get')" loading-text="Carregando Materiais...." :search="search" :items="filteredItems" :headers="headers" dense v-model="selectedItemIds" item-key="id" show-select :items-per-page="-1" show-expand :expanded.sync="expanded" >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="py-4 grey lighten-2 text-start">
              <MaterialExpand :item="item" />
            </td>
          </template>
          <template v-slot:top>
            <!-- Linha de Pesquisar, atualizar e criar nova solicitação equipamento -->
            <v-row class="mt-4" dense>
              <!-- Campo de Pesquisa do endereço -->
              <v-col cols="2">
                <v-text-field dense label="Pesquisar" outlined v-model="search" class="mx-0" append-icon="mdi-magnify" clearable/>
              </v-col>
              <v-col class="text-end">
                <v-badge left bordered overlap :content="activeFilters.length"> <v-btn class="mr-4 elevation-0"  text  v-on:click="showFilters = !showFilters" > <v-icon left> mdi-filter </v-icon> Filtros </v-btn>  </v-badge>
                <!-- Botão de Atualizar a tela -->
                <v-btn class="mr-4 elevation-0" text @click="atualizar"> <v-icon left> mdi-reload </v-icon> atualizar</v-btn >
                <v-btn class="mr-4 elevation-0" text @click="collapseAll"> Recolher Linhas </v-btn>
                <ConfirmButton color="primary" class="mr-4 elevation-0" :disabled="isDesabilitarBotoes || !isRetirarHabilitado" :loading="isLoadingRetirar" :onConfirm="retirarTodos">
                  <div class="d-flex flex-column compact-btn-text"> <span>Solicitar</span> <span>Retirada</span> </div>
                </ConfirmButton>
                <ConfirmButton color="primary" class="mr-4 elevation-0" :disabled="isDesabilitarBotoes || !isCancelar" :loading="isLoadingRetirada" :onConfirm="cancelarOrcamento" >
                  <div class="d-flex flex-column compact-btn-text"> <span>Cancelar</span> <span>Orçamento</span>  </div>
                </ConfirmButton>
                <ConfirmButton  color="primary"  class="mr-4 elevation-0"  :disabled="isDesabilitarBotoes || !isCancelarRequisitar"  :loading="isLoadingCancelarRequiisitar"  :onConfirm="cancelarrequistar" >
                  <div class="d-flex flex-column compact-btn-text">  <span>Cancelar</span> <span>Requisição</span>  </div>
                </ConfirmButton>
                <ConfirmButton color="primary" class="mr-4 elevation-0" :disabled="isDesabilitarBotoes || !isRequisitar" :loading="isLoadingRequiisitar" :onConfirm="requistar" >
                  <div class="d-flex flex-column compact-btn-text"> <span>Requisitar</span>  </div>
                </ConfirmButton>
                <!-- Botão de Criar "+ NOVO" novo endereço -->
                <v-btn color="success" class="elevation-0" @click="dialog.create = true"> <v-icon left> mdi-plus </v-icon> NOVO </v-btn>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="elevation-0 ml-5"
                      v-bind="attrs"
                      v-on="on"
                      style="background-color: transparent;"
                      @click="showIndividualReport ? openModalIndividual() : openModal()"
                    >
                      <v-icon class="align-center mx-8" color="green" size="30">mdi-printer</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ showIndividualReport ? "Gerar Relatório Individual de Material" : "Gerar Checagem de Material" }}
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-card outlined v-show="showFilters" dense>
                <h4 class="ml-4 mt-4">Filtrar por</h4>
                <v-row dense class="py-2 px-4">
                  <v-col cols="2">
                    <v-select  hide-details  :items="statusOptions"  label="Status"  item-text="label"  item-value="value"  v-model="filters.is_active.value"  outlined  dense/>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-row dense>
                    <v-col class="text-end">
                      <v-btn color="error" text elevation="0" @click="clearFilters">  Limpar filtros  </v-btn>
                      <v-btn color="success" class="ml-2" elevation="0" @click="applyFilters">  Aplicar filtros </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
              <span class="white--text">{{ item.ativo ? "Ativo" : "Inativo" }}</span>
            </v-chip>
          </template>

          <template v-slot:[`item.medicamento.classificacao`]="{ item }">
            <v-chip v-if="item.medicamento?.classificacao?.id === 2" color="black"  class="white--text" > Controlado </v-chip>
            <v-chip  v-else-if="item.medicamento?.classificacao?.id === 1" color="orange" class="white--text"  >  Antimicrobiano </v-chip>
            <span v-else>
              <!-- Caso não tenha classificação, manter um espaço em branco -->
            </span>
          </template>
          <template v-slot:[`item.id_medicamento_referencia_complementar`]="{ item }">
            <v-chip
              v-if="
                item.id_medicamento_referencia_complementar || hasCorrespondingId(item)
              "
              color="green"
              class="white--text"
            >
              {{ item.id_medicamento_referencia_complementar || item.id }}
            </v-chip>
            <span v-else>
              {{ item.id_medicamento_referencia_complementar }}
            </span>
          </template>
          <template v-slot:[`item.medicamento.nome_substancia`]="{ item }">
            <v-chip
              v-if="
                item.id_medicamento_referencia_complementar || hasCorrespondingId(item)
              "
              color="green"
              class="white--text"
            >
              {{ item?.medicamento?.nome }} -
              {{ item?.medicamento?.apresentacao_prescricao?.abreviatura }}
            </v-chip>
            <span v-else>
              {{ item?.medicamento?.nome }} -
              {{ item?.medicamento?.apresentacao_prescricao?.abreviatura }}
            </span>
          </template>
          <template v-slot:[`item.sos`]="{ item }">
            <v-chip v-if="item.sos" color="yellow">
              <span> Sim</span>
            </v-chip>
            <v-chip v-else color="white">
              <span> Não</span>
            </v-chip>
          </template>
          <template v-slot:[`item.medicamento.alto_risco`]="{ item }">
            <v-chip v-if="item.medicamento.alto_risco" color="red" class="white--text">
              <span> Sim</span>
            </v-chip>
            <v-chip v-else color="white">
              <span> Não</span>
            </v-chip>
          </template>
          <template v-slot:[`item.medicamento.interacao`]="{ item }">
            <v-chip v-if="item.medicamento.interacao" color="red" class="white--text">
              <span> Sim</span>
            </v-chip>
            <v-chip v-else color="white">
              <span> Não</span>
            </v-chip>
          </template>
          <template v-slot:[`item.medicamento.padrao`]="{ item }">
            <span >{{ item.medicamento.padrao ? 'Sim' : 'Não' }}</span>
          </template>
          <template v-slot:[`item.disponibilidade`]="{ item }">
            <span v-if="!item.id_medicamento_referencia_complementar">{{ item.disponibilidade.nome }}</span>
          </template>
          <template v-slot:[`item.data_inicio`]="{ item }">
            <span v-if="!item.id_medicamento_referencia_complementar">{{ formatDate(item.data_inicio) }}</span>
          </template>
          <template v-slot:[`item.data_fim`]="{ item }">
            <span v-if="!item.id_medicamento_referencia_complementar">{{ formatDate(item.data_fim) }}</span>
          </template>
          <template v-slot:[`item.data_orcamento`]="{ item }">
            <span v-if="!item.id_medicamento_referencia_complementar">{{ formatDate(item.data_orcamento) }}</span>
          </template>
          <template v-slot:[`item.data_requisicao`]="{ item }">
            <span v-if="!item.id_medicamento_referencia_complementar">{{ formatDate(item.data_requisicao) }}</span>
          </template>
          <template v-slot:[`item.data_prevista_implantacao`]="{ item }">
            <span v-if="!item.id_medicamento_referencia_complementar">{{ formatDate(item.data_prevista_implantacao) }}</span>
          </template>
          <template v-slot:[`item.data_implantacao`]="{ item }">
            <span v-if="!item.id_medicamento_referencia_complementar">{{ formatDate(item.data_implantacao) }}</span>
          </template>
          <template v-slot:[`item.data_solicitacao_retirada`]="{ item }">
            <span v-if="!item.id_medicamento_referencia_complementar">{{
              item.data_solicitacao_retirada
                ? formatDate(item.data_solicitacao_retirada)
                : formatDate(item.data_solicitacao_troca)
            }}</span>
          </template>
          <template v-slot:[`item.data_retirada`]="{ item }">
            <span v-if="!item.id_medicamento_referencia_complementar">{{
              item.data_retirada
                ? formatDate(item.data_retirada)
                : formatDate(item.data_troca)
            }}</span>
          </template>
          <template v-slot:no-data>
            <span> Nenhum material encontrado. </span>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <span
              v-if="item.id_medicamento_referencia_complementar === null && item.ativo"
            >
              <v-btn icon @click="onSelectMedicamento(item, 'update')">
                <v-icon color="green">mdi-pencil</v-icon>
              </v-btn>
            </span>
          </template>
        </v-data-table>
        <MaterialPDF v-model="showModal" :showModal="showModal" @update:showModal="showModal = false" :pacienteId="paciente.id" v-if="!showIndividualReport"  ></MaterialPDF>
        <MaterialPDFIndividual v-model="showModalIndividual" :showModal="showModalIndividual" @update:showModal="showModalIndividual = false" :pacienteId="paciente.id" :selectedMedicamento="selectedItemIds" v-if="showIndividualReport" />
      </v-col>
      <!-- Novo Material -->
      <v-dialog
        v-if="dialog.create"
        v-model="dialog.create"
        max-width="90%"
        @input="handleDialogClose"
      >
        <v-card>
          <v-card-title class="blod_color">
            Novo Material:
            <v-spacer />
            <!-- Botão e ícone de fechar a tela -->
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <!-- Abre a página de solicitação de novo medicamento -->
          <v-card-text>
            <NovoMedicamento  :paciente="paciente.id"  :operadoraId="paciente.operadora.id"  :onSubmit="saveMedicamento"  @close="dialog.create = false" />
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Editar um Equipmento -->
      <v-dialog v-if="dialog.update" v-model="dialog.update" max-width="90%" @input="handleDialogClose"  >
        <v-card>
          <v-card-title class="blod_color">
            Editar Material:
              {{
                selectedMedicamento.id_medicamento_referencia_complementar
                  ? `Material Associado ao ID ${selectedMedicamento.id_medicamento_referencia_complementar}`
                  : ""
              }}

            <v-spacer />

            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <!-- {{selectedMedicamento}} -->
            <EditarMedicamento  :medicamento="selectedMedicamento"  :onSubmit="updateMedicamento"  :ativoOptions="ativoOptions"  @close="dialog.update = false" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import api from "@/http";
import UtilsFunc from "@/service/utilsFunc";
import NovoMedicamento from "./Novo.vue";
import EditarMedicamento from "./Editar.vue";
import ConfirmButton from "@/components/ConfirmButton.vue";
import MaterialExpand from "./MaterialExpand.vue";
import MaterialPDF from '/src/components/relatorios/prontuario/material';
import MaterialPDFIndividual from '/src/components/relatorios/prontuario/materialIndividual';

const { withCRUDUtils } = UtilsFunc;

export default {
  name: "MaterialPaciente",
  components: {
    NovoMedicamento,
    EditarMedicamento,
    ConfirmButton,
    MaterialExpand,
    MaterialPDF,
    MaterialPDFIndividual,
  },
  props: {
    paciente: {
      type: Object,
    },
    onUpdate: Function,
  },
  data() {
    return withCRUDUtils({
      medicamentos: [],
      isLoadingRetirar: false,
      isLoadingRetirada: false,
      isLoadingTroca: false,
      isLoadingCancelarRequiisitar: false,
      isLoadingRequiisitar: false,
      updatingSelection: false,
      isLoaded: false, // Nova propriedade
      dialog: {
        create: false,
        update: false,
      },
      ativoOptions: [
        { nome: "Ativo", value: true },
        { nome: "Inativo", value: false },
      ],
      statusOptions: [
        { label: "Ativo", value: true },
        { label: "Inativo", value: false },
      ],
      filters: {
        is_active: { value: true, compareType: "equal", prop: "ativo" },
      },
      selectedMedicamento: null,
      selectedItemIds: [],
      showFilters: false,
      activeFilters: [],
      expanded: [],
      showModal: false,
      showModalIndividual: false,
      headers: [
        { text: "Editar", value: "edit", width: 80 },
        { text: "ID", value: "id", width: 80 },
        { text: "ID Material", value: "medicamento.id" },
        // { text: "ID Ref.", value: "id_medicamento_referencia_complementar" },
        { text: "Material", value: "medicamento.nome_substancia", width: 250 },
        // {
        //   text: "Controlado / Antimicrobiano",
        //   value: "medicamento.classificacao",
        //   width: 110,
        // },
        { text: "SOS", value: "sos" },
        { text: "Padrão", value: "medicamento.padrao" },
        { text: "Qtd", value: "quantidade_medicameneto" },
        { text: "Frequência", value: "frequencia.nome" },
        { text: "Data Início", value: "data_inicio" },
        { text: "Data Fim", value: "data_fim" },
        { text: "Disponibilidade", value: "disponibilidade" },
        { text: "Data Orçamento", value: "data_orcamento" },
        { text: "Data da Requisição", value: "data_requisicao" },
        { text: "Data da Previsão Implantação", value: "data_prevista_implantacao" },
        { text: "Data da Implantação", value: "data_implantacao" },
        // { text: "Observação", value: "obs" },
        { text: "Status", value: "ativo" },
      ],
    });
  },
  methods: {
    // Encontra itens recém-adicionados
    findNewItems(newSelection, oldSelection) {
      return newSelection.filter((n) => !oldSelection.some((o) => o.id === n.id));
    },

    // Encontra itens removidos
    findRemovedItems(newSelection, oldSelection) {
      return oldSelection.filter((o) => !newSelection.some((n) => n.id === o.id));
    },

    // Remove o grupo inteiro ao desselecionar um item
    processRemovals(currentSelection, removedItems) {
      return currentSelection.filter(
        (selected) =>
          !removedItems.some((removed) =>
            this.getRelatedIds(removed).includes(selected.id)
          )
      );
    },

    // Adiciona o grupo inteiro ao selecionar um item
    processAdditions(currentSelection, addedItems) {
      const newSelection = [...currentSelection];

      addedItems.forEach((item) => {
        this.getRelatedIds(item).forEach((id) => {
          if (!newSelection.some((s) => s.id === id)) {
            const relatedItem = this.medicamentos.find((m) => m.id === id);
            if (relatedItem) newSelection.push(relatedItem);
          }
        });
      });

      return newSelection;
    },

    // Obtém IDs de todos os itens relacionados
    getRelatedIds(item) {
      // Converte para número e trata valores nulos/vazios
      const mainId = item.id_medicamento_referencia_complementar
        ? Number(item.id_medicamento_referencia_complementar)
        : item.id;

      return this.medicamentos
        .filter(
          (m) =>
            m.id === mainId || Number(m.id_medicamento_referencia_complementar) === mainId
        )
        .map((m) => m.id);
    },
    async getMaterial() {
      const LOADING_NAME = "get";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get(
          `/pacientes/medicamentos/?material&paciente=${this.paciente.id}`
        );

        // Ordena os dados
        this.medicamentos = data.filter(item => item.medicamento.tipo.id === 2).sort((a, b) => {
          // 1️⃣ Ordenação por `ativo` (true primeiro)
          if (a.ativo !== b.ativo) {
            return b.ativo - a.ativo;
          }

          // 2️⃣ Ordenação por `disponibilidade.id` na ordem específica
          const ordemDisponibilidade = [14, 13, 12, 4, 3, 2, 1];
          const indexA = ordemDisponibilidade.includes(a.disponibilidade?.id)
            ? ordemDisponibilidade.indexOf(a.disponibilidade.id)
            : ordemDisponibilidade.length;
          const indexB = ordemDisponibilidade.includes(b.disponibilidade?.id)
            ? ordemDisponibilidade.indexOf(b.disponibilidade.id)
            : ordemDisponibilidade.length;

          if (indexA !== indexB) {
            return indexA - indexB;
          }

          // 3️⃣ Ordenação por `id_medicamento_referencia_complementar`
          const idRefA = a.id_medicamento_referencia_complementar || a.id;
          const idRefB = b.id_medicamento_referencia_complementar || b.id;

          if (idRefA !== idRefB) {
            return idRefA - idRefB;
          }

          // 4️⃣ Ordenação por `id` dentro do grupo de referência
          if (a.id !== b.id) {
            return a.id - b.id;
          }

          // 5️⃣ Ordenação por `nome_equipamento.nome` (alfabeticamente)
          const nomeA = a.nome_equipamento?.nome?.toLowerCase() || "";
          const nomeB = b.nome_equipamento?.nome?.toLowerCase() || "";
          return nomeA.localeCompare(nomeB);
        });

        this.isLoaded = true;
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, false);
      }
    },
    async updateMedicamento(fields) {
      const LOADING_NAME = "put:equipamento";
      this.setLoading(LOADING_NAME);
      try {
        // console.log('onSubmit --> ',fields)
        await api.put(`pacientes/medicamento/${fields.id}/`, fields);
        // await this.getMaterial()
        await this.atualizar();
        this.$toast.success("Material alterado com sucesso!");
        this.dialog.update = false;
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
        this.dialog.update = false;
        await this.atualizar();
      }
    },
    async saveMedicamento({ medicamentoPrincipal }) {
      const LOADING_NAME = "post:medicamento";
      this.setLoading(LOADING_NAME);
      try {
        // console.log("📌 Criando Material Principal:", medicamentoPrincipal);

        // ✅ Criamos o medicamento principal e pegamos o ID dele
        await api.post( `/pacientes/medicamento_criar/`,medicamentoPrincipal );
      
        await this.getMaterial(); // Atualiza a lista de medicamentos
        this.$toast.success("Material(s) salvo(s) com sucesso!");
        this.dialog.create = false;
      } catch (error) {
        console.error("❌ Erro ao salvar medicamento:", error);
        this.$toast.error(`Desculpe, aconteceu algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, false);
      }
    },
    onSelectMedicamento(medicamentos, action) {
      if (action === "update") {
        this.selectedMedicamento = medicamentos;
        this.dialog.update = true;
      }
    },
    formatDate(isoString) {
      if (!isoString) return "";
      const date = new Date(isoString);
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // Ajuste para compensar a diferença do fuso horário
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    async atualizar() {
      this.isLoaded = false;
      (this.selectedItemIds = []),
        (this.activeFilters = [{ value: true, compareType: "equal", prop: "ativo" }]);
      this.showFilters = false;
      (this.expanded = []), await this.getMaterial();
    },
    async cancelarOrcamento() {
      this.isLoadingRetirada = true;
      try {
        const updatePromises = this.selectedItemIds.map(async (item) => {
          // Se a disponibilidade for 3 (requisitar)
          if (item.disponibilidade.id === 3 || item.disponibilidade.id === 2) {
            await api.put(`pacientes/medicamento/${item.id}/`, {
              id: item.id,
              ativo: false,
              // disponibilidade: 16,
              requisicao: null,
              retirada: null,
              data_fim: this.hojeData,
            });
            this.atualizar();
          } else {
            await api.put(`pacientes/medicamento/${item.id}/`, {
              id: item.id,
              disponibilidade: 1,
              data_solicitacao_retirada: null,
              data_prevista_solicitacao_retirada: null,
              retirada: null,
            });
            // await api.put(`/medicamentos/equipamento_editar/${item.equipamento.id}/`, {
            //     id: item.equipamento.id,
            //     disponibilidade: 1
            //   });
            this.atualizar();
          }
        });

        await Promise.all(updatePromises);

        this.$toast.success("Equipamento alterados com sucesso!");
        this.atualizar();
      } catch (error) {
        this.$toast.error(`Erro ao atualizar os esterilizáveis. ${error}`);
      } finally {
        this.isLoadingRetirada = false;
        this.selectedItemIds = [];
        // await this.getDisponibilidade();
        this.activeFilters = [{ value: true, compareType: "equal", prop: "ativo" }];
        // await this.getEsterilizaveisPaciente();
        this.atualizar();
      }
    },
    async cancelarrequistar() {
      this.isLoadingRetirada = true;
      try {
        // console.log('entrou... ')
        const updatePromises = this.selectedItemIds.map(async (item) => {
          if (item.disponibilidade.id === 3) {
            await api.put(`pacientes/medicamento/${item.id}/`, {
              id: item.id,
              disponibilidade: 2,
              data_requisicao: null,
              data_prevista_implantacao: null,
              requisicao: null,
              retirada: null,
            });
            this.atualizar();
          } else {
            await api.put(`pacientes/medicamento/${item.id}/`, {
              id: item.id,
              disponibilidade: 1,
              data_solicitacao_retirada: null,
              data_prevista_solicitacao_retirada: null,
              data_solicitacao_troca: null,
              data_prevista_solicitacao_troca: null,
              requisicao: null,
              retirada: null,
            });
            // await api.put(`/medicamentos/equipamento_editar/${item.equipamento.id}/`, {
            //     id: item.equipamento.id,
            //     disponibilidade: 1
            //   });
            this.atualizar();
          }
        });
        // console.log(updatePromises)
        await Promise.all(updatePromises);

        this.$toast.success("Equipamento resuisitados com sucesso!");
        this.atualizar();
      } catch (error) {
        this.$toast.error(`Erro ao atualizar os medicamentos. ${error}`);
      } finally {
        this.isLoadingRetirada = false;
        this.selectedItemIds = [];
        this.activeFilters = [{ value: true, compareType: "equal", prop: "ativo" }];
        this.atualizar();
      }
    },
    async requistar() {
      this.isLoadingRetirada = true;
      try {
        // console.log('entrou... ')
        const updatePromises = this.selectedItemIds.map(async (item) => {
          await api.put(`pacientes/medicamento/${item.id}/`, {
            id: item.id,
            disponibilidade: 3,
            data_requisicao: this.hojeData,
            data_prevista_implantacao: this.hojeDataPrevista,
            requisicao: true,
            retirada: null,
          });
          this.atualizar();
        });
        // console.log(updatePromises)
        await Promise.all(updatePromises);

        this.$toast.success("Material(s) requisitado(s) com sucesso!");
        this.atualizar();
      } catch (error) {
        this.$toast.error(`Erro ao atualizar os medicamentos. ${error}`);
      } finally {
        this.isLoadingRetirada = false;
        this.selectedItemIds = [];
        this.activeFilters = [{ value: true, compareType: "equal", prop: "ativo" }];
        this.atualizar();
      }
    },
    async retirarTodos() {
      this.isLoadingRetirar = true;
      try {
        const today = new Date().toISOString().split("T")[0];

        const updatePromises = this.selectedItemIds
          .filter((item) => item.ativo)
          .map(async (item) => {
            if (item.disponibilidade.id === 2) {
              await api.put(`pacientes/equipamento/${item.id}/`, {
                id: item.id,
                ativo: false,
                requisicao: null,
                disponibilidade: 18,
                troca: null,
                retirada: null,
              });
              this.atualizar();
            } else {
              await this.updateMedicamento({
                id: item.id,
                disponibilidade: 12,
                data_prevista_solicitacao_retirada: today,
                data_solicitacao_retirada: today,
                data_prevista_solicitacao_troca: null,
                data_solicitacao_troca: null,
                requisicao: null,
                troca: null,
                retirada: true,
              });
              this.atualizar();
              // Altera a disponibilidade no esterilizável APENAS se NÃO for disponibilidade 3
              if (item.equipamento.disponibilidade.id !== 12) {
                await api.put(
                  `/medicamentos/equipamento_editar/${item.equipamento.id}/`,
                  {
                    id: item.equipamento.id,
                    disponibilidade: 12,
                  }
                );
              }
              this.atualizar();
            }
          });

        await Promise.all(updatePromises);

        this.$toast.success("medicamentos alterados com sucesso!");
        this.atualizar();
      } catch (error) {
        this.$toast.error(`Erro ao atualizar os medicamentos. ${error}`);
      } finally {
        this.isLoadingRetirar = false;
        this.atualizar();
      }
    },
    async applyFilters() {
      this.search = "";
      const filters = JSON.parse(JSON.stringify(this.filters));
      this.activeFilters = Object.values(filters)
        .filter((filter) => filter.value !== undefined)
        .map((filter) => ({
          value: filter.value,
          compareType: filter.compareType,
          prop: filter.prop,
        }));
    },
    hasCorrespondingId(item) {
      return this.medicamentos.some(
        (med) => med.id_medicamento_referencia_complementar == item.id
      );
    },
    clearFilters() {
      this.filters = {
        is_active: { value: true, compareType: "equal", prop: "ativo" },
      };
      this.activeFilters = [{ value: true, compareType: "equal", prop: "ativo" }];
      this.search = "";
    },
    handleDialogClose(val) {
      if (!val) {
        // quando o diálogo fecha, val será false
        this.atualizar();
      }
    },
    closeDialog() {
      this.dialog.create = false;
      this.dialog.update = false;
      this.atualizar();
    },
    async initialize() {
      this.isLoaded = false; // Reseta o estado de carregamento
      await this.getMaterial(); // Atualiza os medicamentos
      this.activeFilters = [{ value: true, compareType: "equal", prop: "ativo" }];
      this.isLoaded = true; // Define que o carregamento foi concluído
      const hoje = new Date();
      const amanha = new Date();
      amanha.setDate(hoje.getDate() + 1); // Garante que o cálculo de dias seja correto

      const formatarData = (data) => {
        return (
          data.getFullYear() +
          "-" +
          String(data.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(data.getDate()).padStart(2, "0")
        );
      };

      this.hojeData = formatarData(hoje);
      this.hojeDataPrevista = formatarData(amanha);
    },
    collapseAll() {
      this.expanded = [];
    },
    openModal() {
      this.showModal = true; // Abre o modal
    },
    closeModal() {
      this.showModal = false; // Fecha o modal
    },
    openModalIndividual() {
      this.showModalIndividual = true; // Abre o modal
    },
    closeModalIndividual() {
      this.showModalIndividual = false; // Fecha o modal
    },
  },
  computed: {
    showIndividualReport() {
      return this.selectedItemIds.length > 0;
    },
    filteredItems() {
      if (!this.medicamentos) return [];

      // Obtém o termo pesquisado e converte para minúsculas
      const searchTerm = this.search.toLowerCase();

      return this.medicamentos.filter((item) => {
        // Filtros ativos
        const activeFilter = this.activeFilters.find((f) => f.prop === "ativo");
        if (
          activeFilter &&
          activeFilter.value !== undefined &&
          item.ativo !== activeFilter.value
        ) {
          return false; // Filtra pelo status se necessário
        }

        // Termos de pesquisa
        const nomeMedicamento = item.medicamento?.nome?.toLowerCase() || "";
        const idMedicamento = String(item.id);
        const idReferencia = String(item.id_medicamento_referencia_complementar || "");

        // Verifica se o termo pesquisado corresponde a algum dos campos
        return (
          nomeMedicamento.includes(searchTerm) ||
          idMedicamento.includes(searchTerm) ||
          idReferencia.includes(searchTerm)
        );
      });
    },
    isRetirarHabilitado() {
      // Verifica se todos os itens selecionados possuem disponibilidade.id === 1
      return (
        this.selectedItemIds.length > 0 &&
        this.selectedItemIds.every((item) => {
          return item.disponibilidade?.id === 1; // Verifica a disponibilidade de cada item selecionado
        })
      );
    },
    isCancelar() {
      // Verifica se todos os itens selecionados possuem disponibilidade.id === 1
      return (
        this.selectedItemIds.length > 0 &&
        this.selectedItemIds.every((item) => {
          return item.disponibilidade?.id === 2; // Verifica a disponibilidade de cada item selecionado
        })
      );
    },
    isCancelarRequisitar() {
      // Verifica se todos os itens selecionados possuem disponibilidade.id === 1
      return (
        this.selectedItemIds.length > 0 &&
        this.selectedItemIds.every((item) => {
          return (
            (item.disponibilidade?.id === 3 ||
            item.disponibilidade?.id === 4 ||
            item.disponibilidade?.id === 12) 
          ); // Verifica a disponibilidade de cada item selecionado
        })
      );
    },
    isRequisitar() {
      // Verifica se todos os itens selecionados possuem disponibilidade.id === 1
      return (
        this.selectedItemIds.length > 0 &&
        this.selectedItemIds.every((item) => {
          return item.disponibilidade?.id === 2 ; // Verifica a disponibilidade de cada item selecionado
        })
      );
    },
    isDesabilitarBotoes() {
      return this.selectedItemIds.length > 0 && this.selectedItemIds.every(item => !item.ativo);
    },
  },
  watch: {
    selectedItemIds: {
      async handler(newSelection, oldSelection) {
        if (this.updatingSelection) return;
        this.updatingSelection = true;

        // Identifica mudanças
        const added = this.findNewItems(newSelection, oldSelection);
        const removed = this.findRemovedItems(newSelection, oldSelection);

        // Processa desseleções
        let updatedSelection = this.processRemovals(newSelection, removed);

        // Processa seleções
        updatedSelection = this.processAdditions(updatedSelection, added);

        // Atualiza estado
        if (JSON.stringify(updatedSelection) !== JSON.stringify(this.selectedItemIds)) {
          this.selectedItemIds = [...new Set(updatedSelection)];
        }

        this.updatingSelection = false;
      },
      deep: true,
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>

<style scoped>
.text-green {
  color: green;
}

.text-red {
  color: red;
}
</style>
