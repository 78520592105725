<template>
  <div>
    <v-form @submit.prevent="localOnSubmit" ref="formRef">
      <v-row dense>
        <v-col>
          <v-autocomplete  dense  label="Material" v-model="medicamento"  :items="medicamentosFiltrados"  :item-text="materialText"  item-value="id"  outlined  :rules="requiredField"  :loading="loadingMedicamento" @change="atualizarMedicamentoSelecionado"/>
        </v-col>
        <v-col cols="2" v-if="medicamento">
          <v-text-field dense label="Como é prescrito" v-model="prescricaoText" outlined readonly ></v-text-field>
        </v-col>
        <v-col v-if="medicamento" cols="2">
          <v-tooltip top >
            <template v-slot:activator="{ on, attrs }">
              <v-alert 
                v-bind="attrs" 
                v-on="on" 
                :type="medicamento.padrao ? 'success' : 'warning'" 
                dense 
                class="text-body-2 text-center"
                style="font-size: 8px; padding: 7px;"
              >
                {{ medicamento.padrao ? 'PADRÃO' : 'NÃO PADRÃO' }}
              </v-alert>
            </template>
            <span>{{ medicamento.padrao ? 'Este medicamento é padronizado na empresa..' : 'Este medicamento não é padronizado na empresa. Provavelmente não temos em estoque.' }}</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="1" >
          <v-text-field dense  label="Qtd." type="number" outlined v-model="quantidade_medicameneto" :rules="requiredField" ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-select  dense  :items="statusOptions"  label="SOS"  v-model="sos"  item-text="label"  item-value="value"  outlined  :rules="requiredField" />
        </v-col>
        <v-col cols="2">
          <v-autocomplete dense label="Responsável Fornecimento" v-model="quemforneceSelecionado" :items="quemfornece" item-value="id" item-text="nome" outlined :rules="requiredField" :loading="loadingGeral" />
        </v-col>
        <v-col >
          <v-text-field dense label="Data Inicio" v-model="dataInicioSelecioanda" required outlined clearable type="date" :rules="requiredField" />
        </v-col>
        <v-col >
          <v-text-field dense label="Data Fim" v-model="dataFimSelecioanda" required outlined clearable type="date"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col v-if="sos === false">
          <v-text-field dense label="Dias de Tratamento" v-model="diasTratamentoSelecionado" required outlined clearable type="number"  />
        </v-col>
        <v-col v-if="sos === false">
          <v-text-field dense label="Quantas Administrações" v-model="quantidadeTratamentoSelecionado" required outlined clearable type="number"  />
        </v-col>
        <v-col v-if="sos === false">
          <v-autocomplete dense label="Frequencia" v-model="frequenciaSelecionada" :items="frequenciasFiltradas" item-value="id" item-text="nome" outlined :rules="requiredField" :loading="loadingGeral" />
        </v-col>
      </v-row>
      <v-row dense >
        
      </v-row>

      <v-row dense >
        <v-col v-if="sos === false">
          <v-autocomplete dense label="Dia da Semana" item-text="nome" v-model="diasSemanaSelecioando" :items="diasSemana" item-value="id" outlined :loading="loadingGeral" multiple chips >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="item" :key="index" close @click:close="removeViaAdministracao(item.id)">
                {{ item.nome }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row dense class="mt-5">
      <v-col  v-if="disponibilidadeescolhida === 2" cols="3">
        <v-text-field dense label="Data de Inclusão no Orçaemnto" v-model="hojeData" required outlined clearable type="date" :rules="requiredField" />
      </v-col>
      <v-col  v-if="disponibilidadeescolhida === 3" cols="3">
        <v-text-field dense label="Data Requisição" v-model="hojeData" required outlined clearable type="date" :rules="requiredField" />
      </v-col>
      <v-col  v-if="disponibilidadeescolhida === 3" cols="3">
        <v-text-field dense label="Data para Entrega" v-model="hojeDataPrevista" required outlined clearable type="date" :rules="requiredField" />
      </v-col>
      </v-row>
      <v-row dense class="mt-5">
        <v-text-field dense label="Observações / Orientações para Prescrição / Instrução" v-model="obsSelecioanda" outlined />
      </v-row>
      <!-- Campos de anexos múltiplos -->
        <v-row dense v-for="(attachment, index) in documentAttachments" :key="index">
          <v-col md="3">
            <v-autocomplete
              dense
              label="Tipo de documento"
              v-model="attachment.documentType"
              :items="documentTypes"
              item-text="nome"
              item-value="id"
              outlined>
            </v-autocomplete>
          </v-col>
          <v-col md="4">
            <div class="drop-zone"
                @dragover.prevent
                @drop.prevent="handleAttachmentDrop(index, $event)">
              <v-file-input
                v-model="attachment.file"
                counter
                show-size
                persistent-hint
                dense
                outlined
                @change="checkAttachmentFileSize(index)">
                <template v-slot:label>
                  <span>Anexar documento <span style="color: red;">*</span></span>
                </template>
              </v-file-input>
              <div class="drop-zone-text">
                Arraste e solte seu arquivo aqui ou clique para selecionar
              </div>
            </div>
          </v-col>
          <v-col md="1">
            <v-btn icon @click="removeAttachment(index)">
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-btn color="primary" @click="addAttachment">
          <v-icon left>mdi-plus</v-icon> Adicionar novo documento
        </v-btn>

      <v-row class="justify-end" dense>
        <!-- <v-col md="3">
                <v-autocomplete outlined dense v-model="selectedDocumentType" :items="documentTypes" item-text="nome" item-value="id">
                <template v-slot:label>
                    <span>Tipo de documento <span style="color: red;">*</span></span>
                </template>
                </v-autocomplete>
            </v-col>
        <v-col md="4">
          <div class="drop-zone" @dragover.prevent @drop.prevent="handleFileDrop">
            <v-file-input
              v-model="selectedFiles"
              multiple
              counter
              show-size
              persistent-hint
              dense
              outlined
              ref="fileInput"
              @change="checkFileSize">
              <template v-slot:label>
                <span>Anexar documento(s) <span style="color: red;">*</span></span>
              </template>
            </v-file-input>
            <div class="drop-zone-text">
              Arraste e solte seus arquivos aqui ou clique para selecionar
            </div>
          </div>
        </v-col> -->


        <v-col class="text-end">
          <v-btn color="red" text class="mr-2" elevation="0" @click="limpar"> limpar Campos </v-btn>
          <ConfirmButton :loading="isSubmitting" color="success">Salvar</ConfirmButton>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template> 

<script>
import UtilsFunc from '../../../../../../service/utilsFunc';
import ConfirmButton from '../../../../../ConfirmButton.vue';
import api from "../../../../../../http";
import { mapGetters } from "vuex";

export default {
  name: "NovoMaterial",
  props: {
    operadoraId: Number,
    onSubmit: Function,
    paciente: Number,
  },
  data: () =>
    UtilsFunc.withCRUDUtils({
      isLoading: true,
      requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório'],
      loadingGeral: false,
      loadingMedicamento: false,
      isFormValid: false,
      isSubmitting: false,
      loading: false,
      documentTypes: [],
      selectedDocumentType: null,
      medicamentos: [], 
      medicamentoCompelementar: [],
      quemfornece: [],
      quemforneceSelecionado: "", 
      diasSemanaSelecioando: "", 
      disponibilidadeOptions: [],
      diasSemana: [],
      frequencia: [],
      viaAdministracao: [],
      classe_terapeutica: [],
      disponibilidadeescolhida: null,
      medicamento: null,
      medicamento_complementar: null,
      frequenciaSelecionada: null,
      sos: null,
      dataInicioSelecioanda: null,
      dataFimSelecioanda: null,
      horaInicioSelecioanda: null,
      // horaFimSelecioanda: null,
      diasTratamentoSelecionado: null,
      quantidade_medicameneto: 1,
      hojeData: null,
      hojeDataPrevista: null,
      viaadministracaoSelecioando: null,
      viaAdministracaoFiltrada: [],
      obsSelecioanda: null,
      quantidadeTratamentoSelecionado: null, 
      expandHorarios: false, // Controla a exibição do painel
      horariosSelecionados: [], // IDs dos horários escolhidos
      horarios: [], // Lista completa dos horários vinda do backend
      quantidade_medicameneto_complementar: 1,
      medicamentosFiltrados: [], // Apenas os medicamentos filtrados pela Classe Terapêutica
      classe_terapeutica_selecionada: null, 
      statusOptions: [
        { label: "Sim", value: true },
        { label: "Não", value: false },
      ],
      selectedFiles: [],
      documentAttachments: [],
      totalDiasSemana: 0,
      qtdAplicacao: 0,
      regraOperadora: [],
      tabelaPropria: [],
      tabela: [], // lista de tabelas
      tabelaSelecionada: null, // tabela escolhida
      tipoguia: [],
      tipoguiaSelecionada: null,
      despesa: [],
      despesaSelecionada: null,
      unidade_cobranca: [],
      sessoes:[],
      quantidadeAjustada: 0,
    }),
  methods: {
    async getDocumentTypes() {
        this.loadingGeral = true
        try {
            const { data } = await api.get("/uteis/tiposdocs/");
            this.documentTypes = data
                .filter(doctype => doctype.ativo && [17, 53, 64, 71, 27,].includes(doctype.id));
        } catch (error) {
            this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
        } finally {
            this.loadingGeral = false
        }
    },
    async getMaterial() {
      this.loadingMedicamento = true;
      try {
        const { data } = await api.get("/matmed/matmeds/");
        this.medicamentos = data.filter(item => item.ativo && item.tipo.id === 2);
        // Garantir que todos os medicamentos sejam listados inicialmente
        this.medicamentosFiltrados = [...this.medicamentos];

        // Aplica o filtro caso já haja uma classe terapêutica selecionada
        // this.filtrarMedicamentosPorClasseTerapeutica();
        // console.log('medicamento', this.medicamentos)
        this.medicamentoCompelementar = data.filter(item => [53, 54].includes(item.id)) // relacionar os medicamdnro complementares
      } catch (error) {
        console.error("❌ Erro ao carregar medicamentos:", error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Erro: ${error}`);
      } finally {
        this.loadingMedicamento = false;
      }
    },
    async getQuemFornece() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get("/pacientes/quem_fornece/");
        this.quemfornece = data.filter(item => item.ativo);
        // console.log("📌 Quem Fornece carregado:", this.quemfornece);
        // ✅ Definir automaticamente o ID 1 caso ele exista na lista
        const fornecimentoPadrao = this.quemfornece.find(item => item.id === 1);
        if (fornecimentoPadrao) {
          this.quemforneceSelecionado = fornecimentoPadrao.id;
        }
      } catch (error) {
        console.error("❌ Erro ao carregar quem fornece:", error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Erro: ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getDisponibilidade() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        this.disponibilidadeOptions = data.filter(item => [3, 2].includes(item.id));
        // console.log(this.disponibilidade)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getDiasSemana() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/uteis/dias_semana/`);
        this.diasSemana = data.filter(item => item.ativo);
        // console.log(this.diasSemana)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
   
    //   this.loadingGeral = true;
    //   try {
    //     const { data } = await api.get(`/uteis/horarios/`);
    //     this.horarios = data.filter(item => item.ativo);
    //     // console.log(this.diasSemana)
    //   } catch (error) {
    //     this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
    //   } finally {
    //     this.loadingGeral = false;
    //   }
    // },
    async getFrequencia() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/atendimentos/frequencias/`);
        this.frequencia = data
          .filter(item => item.ativo)
          .sort((a, b) => a.nome.localeCompare(b.nome));
        // console.log(this.frequencia)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getViaAdministracao() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/matmed/via_administracao/`);
        this.viaAdministracao = data
          .filter(item => item.ativo)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
   
    //   this.loadingGeral = true;
    //   try {
    //     const { data } = await api.get(`/matmed/classe_terapeutica/`);
    //     this.classe_terapeutica = data.filter(item => item.ativo)
    //     // console.log(this.classe_terapeutica)
    //   } catch (error) {
    //     this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
    //   } finally {
    //     this.loadingGeral = false;
    //   }
    // },
    addAttachment() {
    this.documentAttachments.push({ documentType: null, file: null });
    },
    removeAttachment(index) {
      this.documentAttachments.splice(index, 1);
    },
    handleAttachmentDrop(index, event) {
      const files = event.dataTransfer.files;
      if (files.length) {
        this.documentAttachments[index].file = files[0];
        this.checkAttachmentFileSize(index);
      }
    },
    checkAttachmentFileSize(index) {
      const file = this.documentAttachments[index].file;
      if (file && file.size > 2000000) { // 2MB
        this.$toast.error("Um dos arquivos é maior do que o permitido (2MB).");
        this.documentAttachments[index].file = null;
      }
    },
    async updateDocPaciente() {
      const tipopastaMapping = { 17: 8, 27: 16, 53: 15, 64: 13, 71: 16 };
      try {
        for (const attachment of this.documentAttachments) {
          // Certifique-se de que ambos os campos estão preenchidos
          if (!attachment.documentType || !attachment.file) continue;
          let formData = new FormData();
          const tipopastaValue = tipopastaMapping[attachment.documentType] || 1;
          formData.append("arquivo", attachment.file);
          formData.append("tipodoc", attachment.documentType);
          formData.append("tipopasta", tipopastaValue);
          formData.append("ativo", true);
          formData.append("paciente", this.paciente);
          const currentDate = new Date().toISOString().split("T")[0];
          formData.append("data_carregado", currentDate);
          await api.post("/pacientes/docs/", formData);
        }
        this.$toast.success("Documento(s) carregado(s) com sucesso!");
        this.documentAttachments = [];
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Erro: ${error}`);
      }
    },
    async localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }
      // Validação dos anexos:
      // Se houver algum anexo preenchido parcialmente (tipo sem arquivo ou arquivo sem tipo), interrompa a operação.
      const anexosIncompletos = this.documentAttachments.filter(attachment => {
        // Se um dos dois está preenchido e o outro não, é inválido
        return (attachment.documentType && !attachment.file) || (!attachment.documentType && attachment.file);
      });
      if (anexosIncompletos.length > 0) {
        this.$toast.error("Para cada documento, preencha ambos os campos (Tipo de documento e o Arquivo) ou deixe os dois em branco.");
        return;
      }

     
      // }
      this.isSubmitting = true;

      // ✅ Criamos um objeto apenas com as informações do medicamento principal
      const medicamentoPrincipal = {
        paciente: this.paciente,
        operadora: this.operadoraId,
        disponibilidade: 2,
        medicamento: this.medicamento.id,
        quantidade_medicameneto: this.quantidade_medicameneto,
        sos: this.sos,
        quem_fornece: this.quemforneceSelecionado,
        data_inicio: this.dataInicioSelecioanda,
        // hora_inicio: this.horaInicioSelecioanda,
        data_fim: this.dataFimSelecioanda,
        via_administracao: this.viaadministracaoSelecioando,
        data_orcamento: this.hojeData,
        quem_prescreveu: this.userData.apelido,
        obs: this.obsSelecioanda,
      };
      if (!this.sos) {
        medicamentoPrincipal.dias_semana = this.diasSemanaSelecioando;
        medicamentoPrincipal.dias_tratamento = this.diasTratamentoSelecionado;
        medicamentoPrincipal.quantidade_tratamento = this.quantidadeTratamentoSelecionado;
        medicamentoPrincipal.frequencia= this.frequenciaSelecionada;
        // medicamentoPrincipal.horario_administracao = this.horariosSelecionados;
        // medicamentoPrincipal.hora_inicio = this.horaInicioSelecioanda;
        medicamentoPrincipal.data_inicio = this.dataInicioSelecioanda;
        medicamentoPrincipal.data_fim = this.dataFimSelecioanda;
      }

       try {
          await this.onSubmit({ medicamentoPrincipal });
          if (medicamentoPrincipal && Array.isArray(this.sessoes) && this.sessoes.length > 0) {
            const frequenciaObj = this.frequencia.find(f => f.id === this.frequenciaSelecionada);
            const fator = frequenciaObj ? frequenciaObj.fator : 1;

            for (const sessao of this.sessoes) {
              // const hoje = new Date();
              const sessaoInicio = new Date(sessao.data_inicio);
              const sessaoFim = new Date(sessao.data_fim);
              const dataInicioUser = new Date(this.dataInicioSelecioanda);
              const dataFimUser = this.dataFimSelecioanda ? new Date(this.dataFimSelecioanda) : null;

              // Ignora sessões em que dataFimUser está totalmente antes da sessão
              if (dataFimUser && dataFimUser < sessaoInicio) continue;

              // Determina o data_inicio_matmed
              const data_inicio_matmed = (dataInicioUser >= sessaoInicio && dataInicioUser <= sessaoFim)
                ? this.dataInicioSelecioanda
                : sessao.data_inicio;

              // Determina o data_fim_matmed
              let data_fim_matmed;
              if (dataFimUser) {
                if (dataFimUser >= sessaoInicio && dataFimUser <= sessaoFim) {
                  data_fim_matmed = this.dataFimSelecioanda;
                } else {
                  data_fim_matmed = sessao.data_fim;
                }
              } else {
                data_fim_matmed = sessao.data_fim;
              }

              // Calcula quantidade com base no intervalo entre início e fim
              const diffDias = Math.max(1, Math.ceil((new Date(data_fim_matmed) - new Date(data_inicio_matmed)) / (1000 * 60 * 60 * 24) + 1));
              const quantidadeCalculada = Math.ceil(diffDias * fator);

              const fields = {
                sessao: sessao.id,
                matmed: this.medicamento.id,
                codigo: this.codigo,
                data_inicio_matmed,
                data_fim_matmed,
                quantidade_matmed: quantidadeCalculada,
                valor_cobranca_matmed: this.preco,
                valor_cobranca_final_matmed: this.preco * quantidadeCalculada,
                aditivo_matmed: false,
                pacote: sessao.pacote_pacote_id || null,
                operadora: this.operadoraId,
                tabela:  this.tabelaSelecionada ?  this.tabelaSelecionada.id : this.tabela.id,
                despesa: this.despesaSelecionada ? this.despesaSelecionada.id : this.despesa.id,
                tipoguia: this.tipoguiaSelecionada ? this.tipoguiaSelecionada.id : this.tipoguia.id,
                xmlagrupado: 1,
                unidade_medida: this.unidade_medida_cobranca.id,
                frequencia: this.frequenciaSelecionada,
              };

              // console.log(`✅ Enviando para sessão ${sessao.id}`, fields);
              try {
                await api.post('atendimentos/matmed/', fields);
              } catch (error) {
                console.error(`❌ Erro ao enviar para sessão ${sessao.id}`, error);
              }
            }
          }
          
          if (this.documentAttachments.length) {
            await this.updateDocPaciente();
          }
        } finally {
          this.isSubmitting = false;
        }
    },


    atualizarMedicamentoSelecionado() {
      if (!this.medicamento) return;
      
      const medicamentoCompleto = this.medicamentos.find(med => med.id === this.medicamento);
      
      if (medicamentoCompleto) {
        this.medicamento = medicamentoCompleto; // Armazena o objeto completo
        this.atualizarViasAdministracao();
         this.selecionarProduto(this.medicamento);
      }
    },
     atualizarViasAdministracao() {
      if (!this.medicamento || !this.medicamento.via_administracao) {
        this.viaAdministracaoFiltrada = []; // Se não houver medicamento ou vias, limpa a lista
      } else {
        this.viaAdministracaoFiltrada = this.medicamento.via_administracao.map(via => ({
          id: via.id,
          nome: via.nome,
          sigla: via.sigla
        }));
      }
    },
    limpar(){
      this.disponibilidadeescolhida =null,
      this.medicamento =null,
      this.medicamento_complementar =null,
      this.frequenciaSelecionada =null,
      this.sos =null,
      this.dataInicioSelecioanda =null,
      // this.dataFimSelecioanda =null,
      this.horaInicioSelecioanda =null,
      // this.horaFimSelecioanda =null,
      this.diasTratamentoSelecionado =null,
      this.diasSemanaSelecioando = [],
      this.hojeData =null,
      this.hojeDataPrevista =null,
      this.viaadministracaoSelecioando =null,
      this.viaAdministracaoFiltrada = null,
      this.quantidadeTratamentoSelecionado = null,
      this.obsSelecioanda =null,
      this.horariosDisponiveis =[], 
      this.horariosSelecionados =[],
      this.classe_terapeutica = [],
      this.quantidade_medicameneto_complementar = 1,
      this.quantidade_medicameneto = 1,
      this.selectedFiles = null
      this.hoaririosSelecioando = []
      this.selectedDocumentType = null,
      this.inicializar()
      
    },
    inicializar(){
      this.getMaterial();
      // this.filtrarMedicamentosPorClasseTerapeutica();
      // this.getHorarios();
      this.getQuemFornece();
      this.getDisponibilidade()
      this.getDiasSemana()
      this.getFrequencia()
      this.getViaAdministracao()
      // this.getClasseTerapeutica()
      this.getDocumentTypes()
      this.sos = false
      const hoje = new Date();
      const amanha = new Date();
      amanha.setDate(hoje.getDate() + 1);
      const formatarData = (data) => {
        return data.getFullYear() + '-' + 
          String(data.getMonth() + 1).padStart(2, '0') + '-' + 
          String(data.getDate()).padStart(2, '0');
      };
      this.hojeData = formatarData(hoje);
      this.dataInicioSelecioanda = formatarData(hoje);
      this.hojeDataPrevista = formatarData(amanha);
      this.quantidade_medicameneto_complementar = 1;
      this.getTabelas();
      this.getTipoguias();
      this.getDespesas();
      this.getRegraOperadora()
      this.getTabelaPropria()
      this.getUnidadeMedida();
      this.getSessoes()
    },

    marcarDiaDaSemanaAutomaticamente() {
      if (!this.frequenciaSelecionada) return;

      const frequenciasDiarias = [3, 4, 15, 16, 17, 18];
      const frequenciasQueMarcamUmDia = [12, 11, 10, 1, 14, 13];
      
      if (this.diasTratamentoSelecionado) {
          this.marcarDiasTratamento();
          return;
      }

      if (frequenciasDiarias.includes(this.frequenciaSelecionada)) {
          this.diasSemanaSelecioando = this.diasSemana.map(dia => dia.id);
          return;
      }

      if (frequenciasQueMarcamUmDia.includes(this.frequenciaSelecionada)) {
          const hoje = new Date();
          let diaSemanaAtual = hoje.getDay();
          if (diaSemanaAtual === 0 || diaSemanaAtual === 6) {
              diaSemanaAtual = 1;
          }
          this.diasSemanaSelecioando = [diaSemanaAtual];

          return;
      }
    },
    marcarDiasTratamento() {
      if (!this.diasTratamentoSelecionado) return;

      let quantidade = Math.min(this.diasTratamentoSelecionado, 7);
      let hoje = new Date();
      let diaAtual = hoje.getDay();
      diaAtual = diaAtual === 0 ? 7 : diaAtual; // Converte domingo de 0 para 7
      
      let diasSelecionados = [];
      let diasProcessados = 0;
      let proximoDia = diaAtual;
      
      // Continua adicionando dias até atingir a quantidade desejada
      while (diasProcessados < quantidade) {
          // Adiciona o dia atual se ainda não foi adicionado
          if (!diasSelecionados.includes(proximoDia)) {
              diasSelecionados.push(proximoDia);
              diasProcessados++;
          }
          
          // Move para o próximo dia
          proximoDia++;
          // Se passou de domingo (7), volta para segunda (1)
          if (proximoDia > 7) {
              proximoDia = 1;
          }
      }
      
      // Ordena os dias para garantir a sequência correta
      diasSelecionados.sort((a, b) => a - b);
      
      // Atualiza os dias selecionados
      this.diasSemanaSelecioando = diasSelecionados;

      // console.log("Dias selecionados:", diasSelecionados); // Para debug
    },
    marcarDiasTratamentoComFinaisDeSemana() {
      if (!this.diasTratamentoSelecionado) return;

      let quantidade = Math.min(this.diasTratamentoSelecionado, 7); // Limita a 7 dias, já que não podemos ter mais que isso
      let hoje = new Date();
      let diaAtual = hoje.getDay();
      diaAtual = diaAtual === 0 ? 7 : diaAtual; // Converte domingo de 0 para 7
      
      let diasSelecionados = [];
      
      // Primeiro, adicionamos o dia atual
      diasSelecionados.push(diaAtual);
      
      // Então, adicionamos os próximos dias necessários
      let diasRestantes = quantidade - 1;
      let proximoDia = diaAtual;
      
      while (diasRestantes > 0) {
          proximoDia++;
          if (proximoDia > 7) {
              proximoDia = 1; // Volta para segunda quando passar de domingo
          }
          
          if (!diasSelecionados.includes(proximoDia)) {
              diasSelecionados.push(proximoDia);
              diasRestantes--;
          }
      }
      
      // Ordena os dias
      diasSelecionados.sort((a, b) => a - b);
      
      // Atualiza os dias selecionados
      this.diasSemanaSelecioando = diasSelecionados;
    },  
    marcarDiasPorFrequencia(quantidadeDias) {
      let hoje = new Date();
      let diaAtual = hoje.getDay();
      diaAtual = diaAtual === 0 ? 7 : diaAtual; // Converte domingo de 0 para 7
      
      let diasDisponiveis = [1, 2, 3, 4, 5]; // Apenas dias úteis (seg a sex)
      let diasSelecionados = [];
      
      // Remove o dia atual da lista de dias disponíveis se for dia útil
      if (diaAtual <= 5) {
          diasDisponiveis = diasDisponiveis.filter(d => d !== diaAtual);
      }
      
      // Adiciona o dia atual se for dia útil
      if (diaAtual <= 5) {
          diasSelecionados.push(diaAtual);
      }
      
      // Seleciona os dias restantes necessários
      while (diasSelecionados.length < quantidadeDias && diasDisponiveis.length > 0) {
          // Calcula o intervalo ideal entre os dias
          const intervalo = Math.floor(5 / quantidadeDias);
          
          // Encontra o próximo dia adequado
          let proximoDia = diaAtual + intervalo;
          while (proximoDia > 5) proximoDia -= 5;
          
          // Se o dia já estiver selecionado, tenta o próximo
          while (diasSelecionados.includes(proximoDia) && diasDisponiveis.length > 0) {
              proximoDia = proximoDia + 1 > 5 ? 1 : proximoDia + 1;
          }
          
          if (!diasSelecionados.includes(proximoDia)) {
              diasSelecionados.push(proximoDia);
              diasDisponiveis = diasDisponiveis.filter(d => d !== proximoDia);
          }
          
          diaAtual = proximoDia;
      }
      
      // Ordena os dias selecionados
      diasSelecionados.sort((a, b) => a - b);
      
      // Atualiza os dias selecionados
      this.diasSemanaSelecioando = diasSelecionados;
  
      
      // console.log("Dias selecionados para frequência:", diasSelecionados);
    },        
    removeViaAdministracao(id) {
      this.diasSemanaSelecioando = this.diasSemanaSelecioando.filter(via => via !== id);
    },
    lista() {
      return this.userData || {}
    },
    calcularDataFim() {
      if (!this.dataInicioSelecioanda || !this.totalDiasSemana || !this.qtdAplicacao) return;

      const dataInicio = new Date(this.dataInicioSelecioanda);
      
      // console.log('this.totalDiasSemana', this.totalDiasSemana)
      // console.log('this.qtdAplicacao', this.qtdAplicacao)
      // Soma os dias necessários
      const diasSomados = 
        this.totalDiasSemana 
        // + this.qtdAplicacao 
        - 1;
      dataInicio.setDate(dataInicio.getDate() + diasSomados);

      // Atualiza a variável com a nova data formatada
      this.dataFimSelecioanda = dataInicio.toISOString().split('T')[0];

      // console.log("📅 Nova Data Fim:", this.dataFimSelecioanda);
    },
     handleFileDrop(event) {
      const files = event.dataTransfer.files;
      if (files.length) {
        this.selectedFiles = files[0];
        this.checkFileSize();
      }
    },
    // Regras para Precificação
    async getTabelas() {
      const { data } = await api.get('operadoras/tabelas/');
      this.tabela = data.filter(item => item.ativo);
    },
    async getTipoguias() {
      const { data } = await api.get('operadoras/tipoguia/');
      this.tipoguia = data.filter(item => item.ativo);
    },
    async getDespesas() {
      const { data } = await api.get('operadoras/despesas/');
      this.despesa = data.filter(item => item.ativo);
    },
    async getUnidadeMedida() {
      try {
        const { data } = await api.get('uteis/unidade/')
        this.unidade_cobranca = data.filter(unidade_cobranca => unidade_cobranca.ativo)
        const tabelaPadrao = this.unidade_cobranca.find(item => item.id === 88);
        if (tabelaPadrao) {
          this.unidade_medida_cobranca = tabelaPadrao;
        }
        // console.log(this.unidade_cobranca)
      } catch (error) {
        this.$errorHandler(error)
      } 
    },
    async getRegraOperadora() {

      try {
        const { data } = await api.get(`operadoras/tabela_precificacao/`);
        // console.log(data)
        this.regraOperadora = data.filter(matmed => matmed.ativo === true && matmed.operadora.id === this.operadoraId  && matmed.tipo_tabela.id === 1)
        // console.log('Regra Operadora', this.regraOperadora )
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      } 
    },
    async getTabelaPropria() {
      this.loadingmatMed = true
      try {
        const { data } = await api.get(`matmed/tabela_propria/`);
        this.tabelaPropria = data.filter(matmed => matmed.ativo && matmed.operadora.id === this.operadoraId)
        // console.log('Tabela Propria',this.tabelaPropria)
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      } finally {
        this.loadingmatMed = false;
      }
    },
    async selecionarProduto(newVal) {
 
    if (!newVal) return;
    this.loadingPrecoECodigo = true;
    this.preco = null;
    this.codigo = null;
    this.descricao = null;
    


    try {
      // ✅ 1️⃣ Buscar os dados do produto
      let { data: produtoData } = await api.get(`matmed/matmeds/?matmed_id=${newVal.id}`);
      if (Array.isArray(produtoData) && produtoData.length > 0) {
        produtoData = produtoData[0];
      } else {
        this.$toast.error('Nenhum produto encontrado para este ID.');
        return;
      }
      
      this.produtoManipulado = !!produtoData.manipulado;
      // ✅ 2️⃣ Armazenar a descrição do produto, se houver
      this.descricao = produtoData.tabela_medicamento?.descricao || produtoData.tabela_simpro?.descricao || "";

      // ✅ 3️⃣ Verificar se o produto está na Tabela Própria
      // const tabelaPropriaProduto = this.tabelaPropria.find(item => item.produto.id === newVal);
      // ✅ 3️⃣ Verificar se o produto está na Tabela Própria
      // console.log(this.tabelaPropria)
      // console.log(newVal.id)
     
      const tabelaPropriaProduto = this.tabelaPropria.find(item => item.produto?.id === newVal?.id);
      console.log('tabelaPropriaProduto', tabelaPropriaProduto)
      if (tabelaPropriaProduto) {
        console.log('tabela propria')
        this.preco = tabelaPropriaProduto.preco.toFixed(2);
        this.codigo = tabelaPropriaProduto.codigo;
        this.tabelaSelecionada = this.tabela.find(t => t.id === tabelaPropriaProduto.tabela.id) || null;
        this.tipoguiaSelecionada = this.tipoguia.find(t => t.id === tabelaPropriaProduto.tipoguia.id) || null;
        this.despesaSelecionada = this.despesa.find(t => t.id === tabelaPropriaProduto.despesa.id) || null;
        return;
      } else {
        console.log('produtoData', produtoData)
        // const tipoProduto = this.tipoSelecionado;
        if (produtoData.manipulado) {
          await this.definirPrecoManipulado(produtoData);
          console.log('entrou manipulado')
        // } else if (produtoData.tipo.id === 1) {
        //   await this.definirPrecoMedicamento(produtoData);
        //   console.log('entrou medicamento')
        // } else if (produtoData.tipo.id === 3) {
        //   await this.definirPrecoDieta(produtoData);
        //   console.log('entrou Dieta')
        } else if (produtoData.tipo.id === 2) {
          await this.definirPrecoMaterial(produtoData);
          console.log('entrou Material')
        }

      }
      



      // 🔁 Seleciona a unidade de cobrança com base no produto
      if (produtoData.unidade_medida_cobranca) {
        const unidadeId = produtoData.unidade_medida_cobranca.id;
        const unidadeSelecionada = this.unidade_cobranca.find(u => u.id === unidadeId);
        this.unidade_medida_cobranca = unidadeSelecionada || 88;
      } else {
        const unidadePadrao = this.unidade_cobranca.find(u => u.id === 88);
        this.unidade_medida_cobranca = unidadePadrao || 88;
      }



    } catch (error) {
      console.error('Erro ao buscar o produto:', error);
    } finally {
      this.loadingPrecoECodigo = false;
    }
    },
    async definirPrecoManipulado(produtoData) {
   
      // const produtoRestritoHospitalar = produtoData.tabela_medicamento?.restrito_hospitalar || false;
      // ✅ Filtrar as regras da operadora e ordenar por prioridade
      let regrasOrdenadas = this.regraOperadora
      .filter(regra => regra.ativo && regra.manipulado )
      .sort((a, b) => a.ordem - b.ordem);
      regrasOrdenadas = regrasOrdenadas[0]
      // console.log('regrasOrdenadas -> ', regrasOrdenadas)

      if (regrasOrdenadas.length === 0) {
        this.$toast.error('Nenhuma regra válida encontrada.');
        return;
      }

      let precoBase = parseFloat(produtoData.custo) || 0

      if (!precoBase || precoBase <= 0) {
        this.$toast.error('Nenhum preço válido encontrado.');
        return;
      }
      // console.log('regrasOrdenadas --> ', regrasOrdenadas)
      if (regrasOrdenadas.acrescimo) {
        precoBase += (precoBase * regrasOrdenadas.acrescimo) / 100;
      } else if (regrasOrdenadas.desconto) {
        precoBase -= (precoBase * regrasOrdenadas.desconto) / 100;
      }

     this.codigo = produtoData.cod_TUSS 
        || produtoData.codigo 
        || produtoData.id.toString().padStart(10, '0');


      this.preco = precoBase.toFixed(2);
      // this.codigo =  produtoData.id.toString().padStart(10, '0');
      this.tabela = Array.isArray(this.tabela) 
        ? this.tabela.find(t => t.id === regrasOrdenadas.tabela.id) || null 
        : null;

      this.tipoguia = Array.isArray(this.tipoguia) 
        ? this.tipoguia.find(t => t.id === regrasOrdenadas.tipoguia.id) || null 
        : null;

      this.despesa = Array.isArray(this.despesa) 
        ? this.despesa.find(t => t.id === regrasOrdenadas.despesa.id) || null 
        : null;


      if (!regrasOrdenadas) {
        this.$toast.info('Sem regra. Restaurando padrões.');
        this.restaurarValoresPadrao();
        return;
      }
  

    },
    async definirPrecoMedicamento(produtoData) {
      const produtoRestritoHospitalar = produtoData.tabela_medicamento?.restrito_hospitalar || false;

      // ✅ Filtrar as regras da operadora e ordenar por prioridade
      let regrasOrdenadas = this.regraOperadora
        .filter(regra => regra.ativo)
        .sort((a, b) => a.ordem - b.ordem);

      if (produtoRestritoHospitalar) {
        // Se for restrito hospitalar, usa apenas regras compatíveis
        regrasOrdenadas = regrasOrdenadas.filter(regra => regra.restrito_hospitalar);
      } else {
        // Se NÃO for restrito hospitalar, exclui regras que são apenas para restritos
        regrasOrdenadas = regrasOrdenadas.filter(regra => !regra.restrito_hospitalar);
      }


      if (regrasOrdenadas.length === 0) {
        this.$toast.error('Nenhuma regra válida encontrada.');
        return;
      }

      let precoBase = null;
      let regraSelecionada = null;

      for (let regra of regrasOrdenadas) {
        precoBase = regra.pfb
          ? parseFloat(produtoData.tabela_medicamento?.preco_fab_unidade)
          : parseFloat(produtoData.tabela_medicamento?.preco_pmc_unidade);

        if (precoBase && precoBase > 0) {
          regraSelecionada = regra;
          break;
        }
      }

      if (!precoBase || precoBase <= 0) {
        this.$toast.error('Nenhum preço válido encontrado.');
        return;
      }
      // console.log(regraSelecionada)
      if (regraSelecionada.acrescimo) {
        precoBase += (precoBase * regraSelecionada.acrescimo) / 100;
      } else if (regraSelecionada.desconto) {
        precoBase -= (precoBase * regraSelecionada.desconto) / 100;
      }

      let codigo = produtoData.tabela_medicamento?.cod_TUSS
        ? produtoData.tabela_medicamento.cod_TUSS
        : produtoData.tabela_medicamento?.cod_brasindice?.padStart(10, '0');

      this.preco = precoBase.toFixed(2);
      this.codigo = codigo ? String(codigo) : '';

      this.tabela = this.tabela.find(t => t.id === regraSelecionada.tabela.id) || null;
      this.tipoguia = this.tipoguia.find(t => t.id === regraSelecionada.tipoguia.id) || null;
      this.despesa = this.despesa.find(t => t.id === regraSelecionada.despesa.id) || null;

      if (!regraSelecionada) {
        this.$toast.info('Sem regra. Restaurando padrões.');
        this.restaurarValoresPadrao();
        return;
      }

    },
    async definirPrecoDieta(produtoData) {
      // const produtoRestritoHospitalar = produtoData.tabela_medicamento?.restrito_hospitalar || false;

      // ✅ Filtrar as regras da operadora e ordenar por prioridade
      let regrasOrdenadas = this.regraOperadora.sort((a, b) => a.ordem - b.ordem);

      // if (produtoRestritoHospitalar) {
      //   // Se for restrito hospitalar, usa apenas regras compatíveis
      //   regrasOrdenadas = regrasOrdenadas.filter(regra => regra.restrito_hospitalar);
      // } else {
      //   // Se NÃO for restrito hospitalar, exclui regras que são apenas para restritos
      //   regrasOrdenadas = regrasOrdenadas.filter(regra => !regra.restrito_hospitalar);
      // }
        // console.log(regrasOrdenadas)

      if (regrasOrdenadas.length === 0) {
        this.$toast.error('Nenhuma regra válida encontrada.');
        return;
      }

      let precoBase = null;
      let regraSelecionada = null;

      for (let regra of regrasOrdenadas) {
        precoBase = regra.pfb
          ? parseFloat(produtoData.tabela_medicamento?.preco_fab_unidade)
          : parseFloat(produtoData.tabela_medicamento?.preco_pmc_unidade);

        if (precoBase && precoBase > 0) {
          regraSelecionada = regra;
          break;
        }
      }

      if (!precoBase || precoBase <= 0) {
        this.$toast.error('Nenhum preço válido encontrado.');
        return;
      }

      if (regraSelecionada.acrescimo) {
        precoBase += (precoBase * regraSelecionada.acrescimo) / 100;
      } else if (regraSelecionada.desconto) {
        precoBase -= (precoBase * regraSelecionada.desconto) / 100;
      }

      let codigo = produtoData.tabela_medicamento?.cod_TUSS
        ? produtoData.tabela_medicamento.cod_TUSS
        : produtoData.tabela_medicamento?.cod_brasindice?.padStart(10, '0');
      // console.log('codigo dieta-->', codigo)
      this.preco = precoBase.toFixed(2);
      this.codigo = codigo ? String(codigo) : '';

      this.tabela = this.tabela.find(t => t.id === regraSelecionada.tabela.id) || null;
      this.tipoguia = this.tipoguia.find(t => t.id === regraSelecionada.tipoguia.id) || null;
      this.despesa = this.despesa.find(t => t.id === regraSelecionada.despesa.id) || null;

      if (!regraSelecionada) {
        this.$toast.info('Sem regra. Restaurando padrões.');
        this.restaurarValoresPadrao();
        return;
      }

    },
    async definirPrecoMaterial(produtoData) {
      if (!produtoData.tabela_simpro) {
        this.$toast.error('Produto não possui preço na tabela SIMPRO.');
        return;
      }

      // ✅ 1️⃣ Verificar se a operadora tem regras ativas para materiais
      let regrasMateriais = this.regraOperadora
        .filter(regra => regra.ativo && regra.tipo_tabela?.id === 1) // Filtra regras para materiais
        .sort((a, b) => a.ordem - b.ordem); // Ordena por prioridade

      if (regrasMateriais.length === 0) {
        this.$toast.error('Nenhuma regra válida encontrada para materiais.');
        return;
      }

      // ✅ 2️⃣ Buscar o preço base do produto
      let precoBase = parseFloat(produtoData.tabela_simpro?.preco_fab_unidade) || 0;

      if (precoBase === 0) {
        this.$toast.error('Nenhum preço válido encontrado para Material.');
        return;
      }

      // ✅ 3️⃣ Percorrer as regras da operadora até encontrar uma válida
      let regraSelecionada = null;

      for (let regra of regrasMateriais) {
        if (precoBase > 0) {
          regraSelecionada = regra;
          break;
        }
      }

      if (!regraSelecionada) {
        this.$toast.error('Nenhuma regra aplicável encontrada para materiais.');
        return;
      }

      // ✅ 4️⃣ Aplicar acréscimo ou desconto, se houver
      if (regraSelecionada.acrescimo) {
        precoBase += (precoBase * regraSelecionada.acrescimo) / 100;
      } else if (regraSelecionada.desconto) {
        precoBase -= (precoBase * regraSelecionada.desconto) / 100;
      }

      // ✅ 5️⃣ Formatar o código do produto garantindo 10 dígitos
      let codigoMaterial = produtoData.tabela_medicamento?.cod_TUSS
        ? produtoData.tabela_medicamento.cod_TUSS
        : produtoData.tabela_simpro?.cod_simpro?.toString().padStart(10, '0');

      // ✅ 6️⃣ Atualizar os valores no formulário
      this.preco = precoBase.toFixed(2);
      this.codigo = codigoMaterial;
      this.tabela = this.tabela.find(t => t.id === regraSelecionada.tabela.id) || null;
      this.tipoguia = this.tipoguia.find(t => t.id === regraSelecionada.tipoguia.id) || null;
      this.despesa = this.despesa.find(t => t.id === regraSelecionada.despesa.id) || null;

      if (!regraSelecionada) {
          this.$toast.info('Sem regra. Restaurando padrões.');
          this.restaurarValoresPadrao();
          return;
        }

    },
    async getSessoes() {
      const { data } = await api.get(`atendimentos/orcamentos/?paciente=${this.paciente}&hoje=true`);
      this.sessoes = data;
      // console.log('this.sessoes', this.sessoes)
    },
     calcularQuantidadeAjustada() {
      // 💡 Aqui você define a lógica de cálculo da quantidade ajustada
      // Exemplo de cálculo:
      const frequencia = this.frequencia.find(f => f.id === this.frequenciaSelecionada);
      if (!frequencia) return;

      const fator = frequencia.fator || 1;
      let diasSelecionados = this.diasSemanaSelecioando?.length || 0;
      if (diasSelecionados === 0 && this.diasTratamentoSelecionado) {
        diasSelecionados = this.diasTratamentoSelecionado;
      }

      const quantidade = Math.ceil(diasSelecionados * fator);
      this.quantidadeAjustada = quantidade;
    },
  },
  components: { ConfirmButton },
  computed: {
     ...mapGetters(['companyData', 'userData']),
    viaAdministracaoText() {
      return (item) => {
          return `${item.nome} - ${item.sigla} `;
      }
    },
    prescricaoText() {
      return this.medicamento && this.medicamento.apresentacao_prescricao
        ? `${this.medicamento.apresentacao_prescricao.nome} - ${this.medicamento.apresentacao_prescricao.abreviatura}`
        : "Sem prescrição definida";
    },
    materialText() {
      return (item) => {
          return `${item.nome} ${item.padrao ? ` - Material Padrão`: ' - Material NÃO Padrão'} `;
      }
    },
    exibirObsViaSonda() {
      return (
        this.medicamento &&
        this.medicamento.via_sonda &&
        this.medicamento.via_sonda.nome?.toLowerCase() === "sim" &&
        this.medicamento.obs_via_sonda
      );
    },
    altorisco() {
      return (
        this.medicamento &&
        this.medicamento.alto_risco === true
      );
    },
    interacaoMedicamento() {
      return (
        this.medicamento &&
        this.medicamento.interacao === true
      );
    },
    padrao() {
      return this.medicamento && this.medicamento.padrao === true;
    },
    // ✅ Computed para filtrar as frequências dependendo se Dias de Tratamento está preenchido ou não
    frequenciasFiltradas() {
        const frequenciasPermitidas = [3, 4, 15, 16, 17]; // ✅ IDs permitidos se dias de tratamento estiverem preenchidos

        if (this.diasTratamentoSelecionado || this.quantidadeTratamentoSelecionado) {
            // Retorna apenas as frequências permitidas
            return this.frequencia.filter(f => frequenciasPermitidas.includes(f.id));
        }
        return this.frequencia; // Se não houver "Dias de Tratamento", mostra todas as frequências
    },
    // horariosSelecionadosText() {
    //   return this.horariosSelecionados.length
    //     ? this.horariosSelecionados.map(id => this.getHorarioNome(id)).join(', ')
    //     : 'Selecione os horários';
    // },
    
    
  },
  watch: {
   // Sempre que a classe terapêutica for alterada, refaz o filtro
    // classe_terapeutica_selecionada() {
    //   this.filtrarMedicamentosPorClasseTerapeutica();
    //   this.medicamento = null;
    // },
    // Atualiza os horários quando a "Hora Início" mudar
    // horaInicioSelecioanda() {
    //   this.marcarHorariosAutomaticamente();
    // },
    
    diasTratamentoSelecionado(newValue) {
      if (newValue) {
          this.marcarDiasTratamento();
          this.quantidadeTratamentoSelecionado = null; // Zera quantidade de administrações
          
          const frequenciasPermitidas = [3, 4, 15, 16, 17];
          if (this.frequenciaSelecionada && !frequenciasPermitidas.includes(this.frequenciaSelecionada)) {
              this.frequenciaSelecionada = null;
          }
      } else {
          this.frequenciaSelecionada = null;
          this.diasSemanaSelecioando = []; // Limpa os dias selecionados quando não houver dias de tratamento
      }
      if (this.diasTratamentoSelecionado) {
        const dataInicio = new Date(this.dataInicioSelecioanda);
        dataInicio.setDate(dataInicio.getDate() + parseInt(newValue) -1); // Soma os dias informados
        this.dataFimSelecioanda = dataInicio.toISOString().split('T')[0]; // Formata no padrão YYYY-MM-DD
      } else {
        this.dataFimSelecioanda = null; // Reseta se não houver dados válidos
      }
    },
    quantidadeTratamentoSelecionado(newValue) {
      if (newValue) {
        this.diasTratamentoSelecionado = null; // Zera dias de tratamento
        
        // Reset previous selections
        this.diasSemanaSelecioando = [];
        
        // Only proceed if we have both quantity and frequency
        if (this.frequenciaSelecionada) {
          // console.log('aqui...')
          const frequencia = this.frequencia.find(f => f.id === this.frequenciaSelecionada);
          if (frequencia) {
            // Calculate how many days we need based on quantity and frequency
            const administracoesPorDia = frequencia.qt || 1; // Get daily administrations from frequency
            const diasNecessarios = Math.ceil(newValue / administracoesPorDia);
            
            // Limit to 7 days maximum
            const diasAMarcar = Math.min(diasNecessarios, 7);
            
            // Get current day
            let hoje = new Date();
            let diaAtual = hoje.getDay();
            diaAtual = diaAtual === 0 ? 7 : diaAtual; // Convert Sunday from 0 to 7
            
            let diasSelecionados = [];
            let proximoDia = diaAtual;
            
            // Select required number of days
            for (let i = 0; i < diasAMarcar; i++) {
              if (!diasSelecionados.includes(proximoDia)) {
                diasSelecionados.push(proximoDia);
              }
              
              // Move to next day
              proximoDia++;
              if (proximoDia > 7) proximoDia = 1;
            }
            
            // Sort days for consistent display
            diasSelecionados.sort((a, b) => a - b);
            
            // Update selected days
            this.diasSemanaSelecioando = diasSelecionados;
            

            // Update hours based on frequency
            // this.marcarHorariosAutomaticamente();
          }
        }
        
        // Update available frequencies based on quantity
        const frequenciasPermitidas = [3, 4, 15, 16, 17];
        if (this.frequenciaSelecionada && !frequenciasPermitidas.includes(this.frequenciaSelecionada)) {
          this.frequenciaSelecionada = null;
        }
      } else {
        // Reset everything if quantity is cleared
        this.frequenciaSelecionada = null;
        this.diasSemanaSelecioando = [];
        this.horariosSelecionados = [];
      }
      this.qtdAplicacao = newValue.length || 0;
      // console.log("📌 Total de qtd Aplicacaoes:", this.qtdAplicacao);
    },

    // Add a new watcher for frequency when quantity is set
    frequenciaSelecionada(newValue) {
      if (this.quantidadeTratamentoSelecionado && newValue) {
        // Recalculate days when frequency changes with quantity set
        const frequencia = this.frequencia.find(f => f.id === newValue);
        if (frequencia) {
          const administracoesPorDia = frequencia.qt || 1;
          const diasNecessarios = Math.ceil(this.quantidadeTratamentoSelecionado / administracoesPorDia);
          const diasAMarcar = Math.min(diasNecessarios, 7);
          
          let hoje = new Date();
          let diaAtual = hoje.getDay();
          diaAtual = diaAtual === 0 ? 7 : diaAtual;
          
          let diasSelecionados = [];
          let proximoDia = diaAtual;
          
          for (let i = 0; i < diasAMarcar; i++) {
            if (!diasSelecionados.includes(proximoDia)) {
              diasSelecionados.push(proximoDia);
            }
            proximoDia++;
            if (proximoDia > 7) proximoDia = 1;
          }
          
          diasSelecionados.sort((a, b) => a - b);
          this.diasSemanaSelecioando = diasSelecionados;
          // Update hours for the new frequency
          // console.log(this.diasSemanaSelecioando.length)
          
          // this.marcarHorariosAutomaticamente();
          this.calcularQuantidadeAjustada();
        }
      } else {
        // Original frequency behavior when quantity is not set
        if (!newValue) return;
        
        this.diasSemanaSelecioando = [];
        this.calcularQuantidadeAjustada();
        
        const frequenciasSemanais = {
          2: 2,
          5: 3,
          8: 4,
          9: 5
        };
        
        if (frequenciasSemanais[newValue]) {
          this.marcarDiasPorFrequencia(frequenciasSemanais[newValue]);
          this.calcularQuantidadeAjustada();
        } else {
          // this.marcarHorariosAutomaticamente();
          this.marcarDiaDaSemanaAutomaticamente();
          this.calcularQuantidadeAjustada();
        }
        this.calcularQuantidadeAjustada();
      }
    },
    diasSemanaSelecioando(newValue) {
      this.totalDiasSemana = newValue.length || 0;
      // console.log("📌 Total de dias selecionados:", this.totalDiasSemana);
    },
    totalDiasSemana() {
    this.calcularDataFim();
    },
    qtdAplicacao() {
      this.calcularDataFim();
    },
    dataInicioSelecioanda() {
      this.calcularDataFim();
    }

  },
  mounted() {
    this.inicializar()
  },
};
</script>

<style scoped>
.drop-zone {
  border: 2px dashed #ccc;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.drop-zone:hover {
  background-color: #f9f9f9;
}
.drop-zone-text {
  font-size: 0.8rem;
  color: #888;
  margin-top: 5px;
}
</style>

