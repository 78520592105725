<template>
  <v-dialog v-model="showModal" max-width="600px"> 
    <v-card>
      <v-card-title>
        <span class="blod_color">Selecionar a Data Início e Data Fim para gerar o PDF </span>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col >
              <v-text-field v-model="data_inicio" label="data Início" required type="date" outlined dense ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="data_fim" label="Data Fim" required type="date" outlined dense ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="cancelModal">Cancelar</v-btn>
        <v-btn color="green" text @click="confirmModal">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
  import api from "@/http";
  import jsPDF from "jspdf";
  import "jspdf-autotable";
  import { mapGetters } from 'vuex';
  import UtilsPDF from '../../../service/utilsPDF';
  import logo from "../../../assets/logo.png";
  import ona from "../../../assets/ONA_Nivel_3.png";
  import gptw from "../../../assets/GPTW_2023-2024.png";
  import moment from "moment";


  export default {
    name: "IndicadorOperadora",
    data() {
      return {
        loading: false,
        data_inicio: "",
        data_fim: "",
        medicamento: [],
        paciente: [],
        };
    },
    props: {
      pacienteId: Number,
      showModal: {
        type: Boolean,
        required: true
      },
      selectedMedicamento: Array,
    },
    methods: {
      openModal() {
        if (this.medicamento.length === 0) {
          this.loading = true; // Mostra o loading enquanto carrega
        }
      },
      cancelModal() {
        this.$emit('update:showModal', false); // Emite o evento para fechar o modal
      },
      confirmModal() {
        if (!this.data_inicio || !this.data_fim) {
          this.$toast.error("Por favor, preencha todos os campos.");
          return;
        }
        this.getMedicamento()
        this.getPaciente()
        this.$emit('update:showModal', false); // Fecha o modal
        this.getPDF(); // Gera o PDF
      },
      async getPDF() {
        
        // Se não houver nenhum medicamento, exibe mensagem de erro e não gera o PDF
        if (this.medicamento.length === 0) {
          this.$toast.error("Sem dados para gerar o relatório.");
          return;
        }
  
        this.loading = true;
        this.$toast.success(`O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`);
        try {

          const doc = this.generatePDF();
          // Salva o PDF diretamente após a geração
          const filename = `Checagem_de_Medicamento_.pdf`;
          doc.save(filename); // Salva o PDF no navegador
          this.loading = false;
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados:", error);
          this.loading = false;
        }
      },
      drawHeader(doc) {
        try {
          doc.addImage(logo, 10, 6, 22, 20);
          doc.addImage(ona, 250, 8, 15, 15);
          doc.addImage(gptw, 270, 8, 15, 15);
        } catch (error) {
          this.$toast.error("Falha ao adicionar a imagem ao PDF:", error);
        }
        const data = this.paciente[0];
        
        const lineHeight = 6;
        const xFixedPosition = 10;
        let initialYPosition = 35;
        let yPosition = 45;

        doc.setFont("helvetica", "normal");
        doc.setDrawColor(57, 106, 159);
        doc.setLineWidth(1);
        doc.line(2, 2, 295, 2); // linha azul superior

        doc.setLineWidth(0.5); // largura da borda
        doc.roundedRect(5, 30, 250, 20, 3, 3, "S");

        moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      const marginRight = 5; // Defina a margem desejada do lado direito (5px)
      const pageWidth = doc.internal.pageSize.getWidth(); // Obtenha a largura total da página
      const textWidth = doc.getTextWidth(`Gerado por: ${this.userData.apelido}`); // Calcule a largura do texto "Gerado por: userData.apelido"
      const xPosition = pageWidth - textWidth - marginRight; // Calcule a posição x para alinhar o texto à direita
      doc.text(`Gerado por: ${this.userData.apelido}`, xPosition, 28);
      doc.text("Data e Hora da Impressão:", pageWidth - doc.getTextWidth("Data e Hora da Impressão:") - marginRight, 32);
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, pageWidth - doc.getTextWidth(`${UtilsPDF.formatDate(currentDate)} às ${hour}`) - marginRight, 36);

      if (data && data?.data_inicio && data?.data_fim) {
        doc.setFontSize(11);
        doc.text(`Período de: ${UtilsPDF.formatDate(data.data_inicio)} até: ${UtilsPDF.formatDate(data.data_fim)}`, 71, 32);
      }

     // **Cálculo da idade do paciente**
      let idadePaciente = "";

      if (data && data.data_nascimento) {
          try {
              const birthDate = moment(data.data_nascimento, "YYYY-MM-DD"); // Converte a data de nascimento para moment
              const currentDate = moment(); // Garante que a data atual também seja um objeto moment
              idadePaciente = currentDate.diff(birthDate, 'years'); // Calcula idade em anos
          } catch (error) {
              console.error("Erro ao calcular a idade:", error);
              idadePaciente = "Erro";
          }
      }
      doc.setFontSize(12);
      doc.text(`Paciente: ${data && data.nome ? data.nome : ''}     Sexo Biológico: ${data && data.sexo_biologico ? data.sexo_biologico : ''}`, xFixedPosition, initialYPosition);

      yPosition = initialYPosition + lineHeight
      const dataNascimento = data && data.data_nascimento ? `Data Nascimento: ${UtilsPDF.formatDate(data.data_nascimento)} - ${idadePaciente} anos` : 'Data Nascimento: ';
      doc.text(dataNascimento, 250, initialYPosition, { align: "right" });

      doc.text(`Operadora: ${data && data.operadora ? data.operadora.nome : ''}   -   Modalidade: ${data && data.plano_terapeutico ? data.plano_terapeutico[0].sessao__modalidade__nome : ''}`, xFixedPosition, yPosition);
      doc.text(`Nº da Carteira: ${data && data.nr_carteirinha ? data.nr_carteirinha : ''}`, 250, yPosition, { align: "right", });

      yPosition += lineHeight
      doc.text(`Alergia: ${data && data.alergia ? data.alergia : ''}`, xFixedPosition, yPosition);

      const maxWidth = 250;
      const spaceBetweenTexts = 10; // Espaço entre modalidadeText e CID

      const modalidadeText = `Modalidade: ${data && data.modalidade ? data.modalidade : ''}`;
      const modalidadeTextWidth = doc.getTextWidth(modalidadeText);

      if (data && data.cid && data.cid.descricao) {
        const cid = data.cid.nome || '';
        const cidDescricao = data.cid.descricao || '';
        const fullText = `CID: ${cid} - ${cidDescricao}`;

        const renderTextLine = (doc, text, x, y, align = 'left') => {
          doc.setFont("helvetica", "normal");
          doc.text(text, x, y, { align: align });
        };

        const remainingWidth = maxWidth - modalidadeTextWidth - spaceBetweenTexts;
        const splittedText = doc.splitTextToSize(fullText, remainingWidth);

        splittedText.forEach((line, i) => {
          const xPos = i === 0 ? 250 : 10;
          const align = i === 0 ? 'right' : 'left';
          const yPos = yPosition + (lineHeight * i);
          renderTextLine(doc, line, xPos, yPos, align);
        });

        yPosition += (lineHeight * splittedText.length);

      } else {
        doc.setFont("helvetica", "normal");
        doc.text(`CID: `, 200, yPosition, { align: "right" });
      }

        doc.setFont("helvetica", "bold");
        doc.setFontSize(12);
        doc.text("SÃO LUÍS HOME CARE", 140, 8, { align: "center" });
        doc.text("CHECAGEM DE MEDICAMENTO", 138, 15, { align: "center" });
        doc.text(`Vigência: ${UtilsPDF.formatDate(this.data_inicio)} até ${UtilsPDF.formatDate(this.data_fim)}.`, 138, 23,{ align: "center" });
        doc.setFont("helvetica", "normal");
        doc.setFontSize(9);
        doc.text(`Médico(a) Responsável: ${ data && data.medico ? data.medico.nome : 'Não Cadastrado'} - COREN: ${ data && data.medico ? data.medico.nconselho : 'Não Cadastrado'}`, 138, 28,{ align: "center" });
      },
      generatePDF() {
        // Cria o documento em paisagem, unidade mm e formato A4
        const doc = new jsPDF({ orientation: 'landscape', unit: 'mm', format: 'a4' });

        // Desenha o cabeçalho
        this.drawHeader(doc);

        // Configurações gerais da fonte e tamanho para a tabela
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");

        // --- INÍCIO DA CONSTRUÇÃO DA TABELA ---
        const maxDateColumns = 8; // Número máximo de colunas de datas por página (além da coluna "Descrição")
        const start = moment(this.data_inicio, "YYYY-MM-DD");
        const end = moment(this.data_fim, "YYYY-MM-DD");

        let allDates = [];
        // Loop para gerar um array com todas as datas formatadas ("DD/MM/YYYY") e seu respectivo ID numérico do dia da semana
        for (let m = start.clone(); m.isSameOrBefore(end); m.add(1, 'days')) {
            allDates.push({
                date: m.format("DD/MM/YYYY"),
                dayOfWeek: m.isoWeekday() // Obtém o ID numérico do dia da semana (1=Segunda, 2=Terça, ..., 7=Domingo)
            });
        }

        // 2. Divide o array de datas em "chunks" (fatias) de tamanho máximo maxDateColumns
        const dateChunks = [];
        for (let i = 0; i < allDates.length; i += maxDateColumns) {
            dateChunks.push(allDates.slice(i, i + maxDateColumns));
        }

        let currentY = 52; // Posição Y inicial para a tabela
        dateChunks.forEach((dateChunk, chunkIndex) => {
          // Define o cabeçalho da tabela: inclui a data e o nome do dia da semana
          const tableHeader = [
              ["Descrição", "Freq.", ...dateChunk.map(d => `${d.date}\n${moment(d.date, "DD/MM/YYYY").format("dddd")}`)]
          ];

          // Cria o corpo da tabela: para cada item de medicamento, cria uma linha.
          const tableBody = this.medicamento.map(item => {
              const descricao = ` ${item.medicamento.nome_substancia}` + 
                      `${item.medicamento?.classificacao?.id === 2 ? "\n CONTROLADO" : (item.medicamento?.classificacao?.id === 1 ? "\n Antibiótico" : '')}` +
                      `${item.sos ? '': `\nDosagem: ${item.quantidade_medicameneto} - ${item.medicamento?.apresentacao_prescricao.abreviatura}`}` +
                      `${item.medicamento?.alto_risco === true ? `\nAlto Risco -> ${item.medicamento.qual_risco}`: ""}`+
                      `${item.medicamento?.interacao === true ? `\nRisco Interação -> ${item.medicamento.obs_interacao}`: ""}`+
                      `${item.sos ? '\n >> "SE NECESSÁRIO" <<' : ""}` +
                      `\nVia de Administração: ${item.via_administracao?.nome} - ${item.via_administracao?.sigla}` +
                      `\nResp. pelo Fornec.: ${item.quem_fornece?.nome}` +
                      `${item.obs !== null ? `\nOrientação -> ${item.obs}`: ""}`;
                      
              const frequencia = `${item.frequencia ? item.frequencia?.nome : ""}`;

              // 🔹 Obtém os IDs numéricos dos dias permitidos (1=Segunda, 2=Terça, etc.)
              const diasPermitidos = item.dias_semana.map(d => parseInt(d.id));

              // 🔹 Preenche os horários apenas nos dias permitidos
              const rowData = dateChunk.map(({ date, dayOfWeek }) => {
                  if (!diasPermitidos.includes(dayOfWeek)) {
                      return ""; // 🔹 Se o dia não estiver permitido, a célula fica vazia
                  }

                  let horarios = item.horario_administracao
                      .filter(h => {
                          const dataItem = moment(item.data_inicio, "YYYY-MM-DD");
                          const dataFimItem = item.data_fim ? moment(item.data_fim, "YYYY-MM-DD") : null;
                          const currentDate = moment(date, "DD/MM/YYYY");

                          // 🔹 Verifica se a data está dentro do intervalo permitido
                          if (dataFimItem) {
                              if (!currentDate.isBetween(dataItem, dataFimItem, null, "[]")) {
                                  return false;
                              }
                          }

                          // 🔹 Se for a última data, filtra horários maiores que a hora_fim
                          if (item.hora_fim && currentDate.isSame(dataFimItem, "day")) {
                              return h.nome <= item.hora_fim;
                          }

                          return true;
                      })
                      .map(h => h.nome)
                      .join("; "); // 🔹 Junta os horários separados por ";"

                  return horarios || "";
              });

              return [descricao, frequencia, ...rowData];
            });

            // Configuração da largura das colunas
            const columnStyles = {
                0: { cellWidth: 70 }, // Primeira coluna "Descrição" com 70mm de largura
                1: { cellWidth: 25 }  // Coluna "Freq." com 25mm de largura
            };

            // Desenha a tabela usando jsPDF-Autotable
            doc.autoTable({
                head: tableHeader,
                body: tableBody,
                startY: currentY,
                theme: "grid",
                styles: {
                    fontSize: 8,
                    cellPadding: 2,
                    halign: "center",
                    valign: "middle"
                },
                headStyles: {
                    fillColor: [57, 106, 159], // Fundo azul para o cabeçalho
                    textColor: [255, 255, 255], // Texto branco
                    fontStyle: "bold",
                    halign: "center",
                    valign: "middle"
                },
                columnStyles: columnStyles,
                margin: { left: 5, right: 5 }
            });

            // Atualiza a posição Y para a próxima tabela (caso haja mais fatias de datas)
            currentY = doc.lastAutoTable.finalY + 10; // 10mm de espaço entre tabelas

            

            // Se ainda houver mais fatias de datas, adiciona uma nova página e redesenha o cabeçalho
            if (chunkIndex < dateChunks.length - 1) {
                doc.addPage();
                this.drawHeader(doc);
                currentY = 52; // Reinicia a posição Y na nova página
            }
        });

        // 🔹 Adiciona o carimbo do medico no final da última página, sem sobrescrever a tabela
        const item = this.paciente[0];

        if (item && item.medico && item.medico.carimbo) {
            let carimbo = item.medico.carimbo;
            const params = item.medico.carimbo.split('://');
            if (params[0] === 'http' && params[1] !== 'localhost') {
                carimbo = carimbo.replace('http', 'https');
            }

            try {
                const pageHeight = doc.internal.pageSize.getHeight();
                let lastTableY = doc.lastAutoTable.finalY || 0;
                const marginBottom = 30; // Margem de segurança inferior
                const requiredSpace = 40; // Espaço necessário para a assinatura
                const spaceLeft = pageHeight - lastTableY - marginBottom; // Calcula o espaço disponível abaixo da tabela
              
                // Se não houver espaço suficiente, adiciona uma nova página
                if (spaceLeft < requiredSpace) {
                    doc.addPage();
                    lastTableY = 20; // Reinicia a posição na nova página
                }

                const xPosition = doc.internal.pageSize.getWidth() - 55; // Posição X (direita da página)
                const yPosition = lastTableY + 20; // Posição Y abaixo da última tabela

                doc.setFont("helvetica", "bold");
                // doc.text("Enfermeiro Responsável:", xPosition - 15, yPosition - 5);
                doc.addImage(carimbo, "PNG", xPosition, yPosition, 50, 25);

            } catch (error) {
                this.$toast.error("Erro ao carregar a imagem do carimbo:", error);
            }
        }
        // Adiciona números de página
        UtilsPDF.addPageNumbersPrescricao(doc);

        return doc; // Retorna o objeto doc para posterior salvamento
      },
      async download(dataurl, filename) {
        const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl
        const res = await fetch(httpsurl)
        const buffer = await res.blob()
        const url = URL.createObjectURL(buffer)
        const link = document.createElement("a");
        link.download = filename;
        link.href = url;
        link.click();
      },
      async getMedicamento() {
        try {
          let medicamentos = [];

          if (this.selectedMedicamento && this.selectedMedicamento.length > 0) {
            // 🔹 Se a prop `selectedMedicamento` foi passada, usamos ela diretamente
            medicamentos = this.selectedMedicamento;
          } else {
            // 🔹 Caso contrário, buscamos os medicamentos via API
            const response = await api.get(
              `/pacientes/medicamentos/?medicamento&paciente=${this.pacienteId}`
            );
            medicamentos = response.data;
          }

          // 🔹 Criamos um mapa para associar medicamentos principais aos seus complementares
          let mapaMedicamentos = {};

          medicamentos.forEach((med) => {
            if (med.id_medicamento_referencia_complementar) {
              // Se for um medicamento complementar, armazenamos no mapa
              if (!mapaMedicamentos[med.id_medicamento_referencia_complementar]) {
                mapaMedicamentos[med.id_medicamento_referencia_complementar] = [];
              }
              mapaMedicamentos[med.id_medicamento_referencia_complementar].push(
                med.medicamento.nome_substancia
              );
            }
          });

          // 🔹 Filtramos os medicamentos principais e adicionamos os complementares ao nome
          this.medicamento = medicamentos
            .filter((med) => !med.id_medicamento_referencia_complementar && med.ativo)
            .map((med) => {
              if (mapaMedicamentos[med.id]) {
                med.medicamento.nome_substancia +=
                  " ** Administrado com " + mapaMedicamentos[med.id].join(" + ");
              }
              return med;
            })
            .sort((a, b) => Number(a.sos) - Number(b.sos)); // 🔹 Ordenação: SOS primeiro

        } catch (error) {
          this.$toast.error("Erro ao carregar medicamentos.");
        }
      },
      async getPaciente() {
        try {
          const { data } = await api.get(  `/pacientes/detalhes_relatorio/?paciente=${this.pacienteId}`);
          this.paciente = data
          // console.log('paciente->', this.paciente)
        } catch (error) {
          this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
        }
      },
      lista() {
        return this.userData || {}
      },
    },
    computed:{ ...mapGetters(['companyData', 'userData']),},
    mounted(){
      this.getMedicamento()
      this.getPaciente()
    },
    watch: {
      showModal(val) {
        if (val) {
          this.openModal(); // Abre o modal quando showModal for true
          this.getMedicamento()
          this.getPaciente()
        }
      }
    },
  };
  </script>
