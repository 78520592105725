<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">

    <v-row dense>
      <!-- Autocomplete para Descrição -->
      <v-col>
        <v-autocomplete dense label="Descrição" v-model="aprazamentoSelecionado" :items="[medicamento.aprazamento]"   item-text="nome" item-value="id" outlined :rules="requiredField" :loading="loadingMedicamento" disabled/>
      </v-col>
      <!-- Autocomplete para Grupo -->
      <v-col>
        <v-autocomplete  dense  label="Grupo"  v-model="grupoSelecionado"  :items="grupo" :value="medicamento.grupo_aprazamento" item-text="nome"  item-value="id"  outlined  :rules="requiredField"  :loading="loadingMedicamento" />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-text-field  dense  label="Data Início"  v-model="dataInicio"  type="date"  required  outlined  :rules="requiredField" />
      </v-col>
      <v-col>
        <v-text-field  dense  label="Hora Início"  v-model="horaInicio"  type="time"  required  outlined  :rules="requiredField"/>
      </v-col>
      <v-col>
        <v-text-field dense label="Data Fim" v-model="dataFim" type="date" required outlined />
      </v-col>
      <v-col>
        <v-text-field  dense  label="Hora Fim"  v-model="horaFim"  type="time"  required  outlined  />
      </v-col>
      <v-col>
        <v-text-field dense label="Dias de Tratamento" v-model="diasTratamento" type="number" required outlined />
      </v-col>
      <v-col>
        <v-autocomplete  dense  label="Frequência"  v-model="frequenciaSelecionada"  :items="frequencia" :value="medicamento.frequencia" item-text="nome"  item-value="id"  outlined  :rules="requiredField"  :loading="loadingGeral" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col >
        <v-autocomplete dense label="Dia da Semana" item-text="nome" v-model="diasSelecionado" :value="medicamento.dias_seman" :items="diasSemana" item-value="id" outlined :loading="loadingGeral" multiple chips >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="item" :key="index" close @click:close="removeViaAdministracao(item.id)">
              {{ item.nome }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col >
          <!-- Campo para exibir os horários selecionados -->
          <v-text-field dense label="Horários" :value="horariosSelecionadosText" outlined readonly hide-details @click="toggleExpand">
            <template v-slot:append>
              <v-icon @click.stop="toggleExpand">
                {{ expandHorarios ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </template>
          </v-text-field>

          <!-- Expansão para exibir os horários -->
          <v-expand-transition>
            <v-card v-if="expandHorarios" class="mt-2 pa-2" outlined>
              <v-row dense>
                <v-col v-for="(horario) in horarios" :key="horario.id" cols="2">
                  <v-checkbox v-model="horariosSelecionados" :label="horario.nome" :value="horario.id" dense hide-details ></v-checkbox>
                </v-col>
              </v-row>
            </v-card>
          </v-expand-transition>
        </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-text-field  dense  label="Observações / Orientações"  v-model="observacoes"  outlined />
      </v-col>
    </v-row>
    <v-row dense>
      <v-btn-toggle v-model="medicamento.ativo" mandatory class="white--text">
        <v-btn :color="!medicamento.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="medicamento.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
    </v-row>

    <v-row class="justify-end" dense>
      <v-col class="text-end">
        <v-btn color="red" text @click="limpar">Limpar Campos</v-btn>
        <ConfirmButton :loading="isSubmitting" color="success">
          Salvar
        </ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import UtilsFunc from '../../../../../../service/utilsFunc';
import ConfirmButton from '../../../../../ConfirmButton.vue';
import api from "../../../../../../http";
import { mapGetters } from "vuex";


export default {
  name: "NovoMedicamento",
  components: { ConfirmButton},
  props: {
    operadoraId: Number,
    onSubmit: Function,
    paciente: Number,
    medicamento: { type: Object, default: null }
  },
  data: () =>
    UtilsFunc.withCRUDUtils({
      requiredField: [v => !!v || 'Obrigatório'],
      loadingGeral: false,
      loadingMedicamento: false,
      isSubmitting: false,
      // Campos do formulário:
      aprazamentoSelecionado: null,
      grupoSelecionado: null,
      dataInicio: "",
      horaInicio: "",
      dataFim: "",
      horaFim: "",
      diasTratamento: "",
      frequenciaSelecionada: null,
      diasSelecionado: null,
      observacoes: "",
      grupo: [],
      // Exemplo de opções para frequência:
      frequencia: [],
      diasSemana: [],
      horarios: [],
      expandHorarios: false, // Controla a exibição do painel
      horariosSelecionados: [], // IDs dos horários escolhidos
  }),
  mounted() {
    this.getGrupoAprazamento()
    this.getFrequencia()
    this.getDiasSemana()
    this.getHorarios()
    if (this.medicamento && this.medicamento.horario_administracao && 
          Array.isArray(this.medicamento.horario_administracao)) {
        // Extraímos apenas os IDs do array de objetos
        this.horariosSelecionados = this.medicamento.horario_administracao.map(horario => horario.id);
      } else {
        this.horariosSelecionados = [];
      }
    // Se o objeto medicamento foi passado, inicializa os campos
    if (this.medicamento) {
      this.aprazamentoSelecionado = this.medicamento.aprazamento;
      this.grupoSelecionado = this.medicamento.grupo_aprazamento;
      this.frequenciaSelecionada = this.medicamento.frequencia;
      this.dataInicio = this.medicamento.data_inicio;
      this.horaInicio = this.medicamento.hora_inicio;
      this.dataFim = this.medicamento.data_fim;
      this.horaFim = this.medicamento.hora_fim;
      this.diasTratamento = this.medicamento.dias_tratamento;
      this.observacoes = this.medicamento.obs;
      this.medicamento.ativo = Number(this.medicamento.ativo);
      if (this.medicamento) {
        this.medicamentoOriginal = JSON.parse(JSON.stringify(this.medicamento));
      }
      this.diasSelecionado = this.medicamento.dias_semana;
  
    }
  },
  methods: {
     async getGrupoAprazamento() {
      this.loadingMedicamento = true;
      try {
        const { data } = await api.get("/pacientes/grupo/");
        this.grupo = data.filter(item => item.ativo );
        // Garantir que todos os medicamentos sejam listados inicialmente
      } catch (error) {
        console.error("❌ Erro ao carregar medicamentos:", error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Erro: ${error}`);
      } finally {
        this.loadingMedicamento = false;
      }
    },
    async getFrequencia() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/atendimentos/frequencias/`);
        this.frequencia = data
          .filter(item => item.ativo)
          .sort((a, b) => a.nome.localeCompare(b.nome));
        // console.log(this.frequencia)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getDiasSemana() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/uteis/dias_semana/`);
        this.diasSemana = data.filter(item => item.ativo);
        // console.log(this.diasSemana)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getHorarios() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/uteis/horarios/`);
        this.horarios = data.filter(item => item.ativo);
        // console.log(this.diasSemana)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    toggleExpand() {
      this.expandHorarios = !this.expandHorarios;
    },
    removeHorario(id) {
      this.horariosSelecionados = this.horariosSelecionados.filter(h => h !== id);
    },
    getHorarioNome(id) {
      const horario = this.horarios.find(h => h.id === id);
      return horario ? horario.nome : 'Desconhecido';
    },
    getModifiedFields(current, original) {
      let modified = {};
      // Percorre todas as chaves do objeto "current"
      for (const key in current) {
        // Sempre ignora o campo "id" (pois ele será enviado separadamente)
        if (key === 'id') continue;
        // Se o valor atual for diferente do original...
        if (JSON.stringify(current[key]) !== JSON.stringify(original[key])) {
          modified[key] = current[key];
        }
      }
      return modified;
    },

    async localOnSubmit() {
      // Valida o formulário
      const valid = this.$refs.formRef.validate();
      if (!valid) {
        this.$toast.error("Preencha todos os campos obrigatórios!");
        return;
      }
      if (!this.dataFim && this.horaFim) {
         this.$toast.error("O Horário final só pode ser cadastrado com DATA FIM preenchido!");
        return;
      }
     
      this.isSubmitting = true;
      
      // Cria um objeto com os dados atuais do formulário
      const currentData = {
        id: this.medicamento.id,
        grupo_aprazamento: this.grupoSelecionado.id,
        frequencia: this.frequenciaSelecionada,
        dias_tratamento: this.diasTratamento,
        data_inicio: this.dataInicio,
        hora_inicio: this.horaInicio,
        data_fim: this.dataFim ? this.dataFim : null,
        hora_fim: this.horaFim ? this.horaFim : null,
        dias_semana: this.diasSelecionado.map(item => (item.id ? item.id : item)),
        horario_administracao: this.horariosSelecionados,
        obs: this.observacoes,
        ativo: this.medicamento.ativo,
      };

      // Compara com a cópia original (armazenada no mounted)
      const changes = this.getModifiedFields(currentData, this.medicamentoOriginal);

      // Sempre inclui o id
      const payload = { id: this.medicamento.id, ...changes };

      if (Object.keys(changes).length === 0) {
        this.$toast.info("Nenhuma alteração detectada.");
        this.isSubmitting = false;
        return;
      }

      try {
        // Envia o payload contendo somente os campos alterados
        // console.log(payload)
        await this.onSubmit(payload);
        this.$toast.success("Salvo com sucesso!");
      } catch (error) {
        console.error("Erro ao salvar:", error);
        this.$toast.error(`Erro ao salvar: ${error}`);
      } finally {
        this.isSubmitting = false;
      }
    },
    limpar() {
      this.aprazamentoSelecionado = null;
      this.grupoSelecionado = null;
      this.dataInicio = "";
      this.horaInicio = "";
      this.dataFim = "";
      this.horaFim = "";
      this.diasTratamento = "";
      this.frequenciaSelecionada = null;
      this.observacoes = "";
    },
    removeViaAdministracao(id) {
      // Verifica se é um array antes de tentar filtrar
      if (Array.isArray(this.medicamento.dias_semana)) {
        this.medicamento.dias_semana = this.medicamento.dias_semana.filter(dia => dia !== id);
      }
    },
    lista() {
      return this.userData || {}
    },
  },
  computed: {
    ...mapGetters(['companyData', 'userData']),
    horariosSelecionadosText() {
      if (!this.horariosSelecionados.length || !this.horarios || this.horarios.length === 0) {
        return "Selecione os horários";
      }

      return this.horariosSelecionados
        .map(id => {
          const horario = this.horarios.find(h => h.id === id);
          return horario ? horario.nome : "Desconhecido";
        })
        .join(", ");
    },
  },
  watch:{
    'medicamento.horario_administracao': function(newValue) {
      if (newValue && Array.isArray(newValue)) {
        this.horariosSelecionados = newValue.map(horario => horario.id);
      } else {
        this.horariosSelecionados = [];
      }
    },
  }
};
</script>
