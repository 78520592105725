<template>
  <div>
    <v-form @submit.prevent="localOnSubmit" ref="formRef">
      <!-- <p>Usuário logado: {{ userData.apelido }}</p> -->
      <v-row dense>

        <v-col>
          <v-autocomplete  dense  label="Descrição" v-model="aprazamentoSelecionado"  :items="aprazamento"  item-text="nome"  item-value="id"  outlined  :rules="requiredField"  :loading="loadingMedicamento" />
        </v-col>
        <v-col>
          <v-autocomplete  dense  label="Grupo" v-model="grupoSelecionado"  :items="grupo"  item-text="nome" item-value="id"  outlined  :rules="requiredField"  :loading="loadingMedicamento" />
        </v-col>
      </v-row>
     
      <v-row dense>
        <v-col >
          <v-text-field dense label="Data Inicio" v-model="dataInicioSelecioanda" required outlined clearable type="date" :rules="requiredField" />
        </v-col>
        <v-col >
          <v-text-field dense label="Hora Início" v-model="horaInicioSelecioanda" required outlined clearable type="time" :rules="requiredField" />
        </v-col>
        <v-col >
          <v-text-field dense label="Data Fim" v-model="dataFimSelecioanda" required outlined clearable type="date"/>
        </v-col>
        <v-col >
          <v-text-field dense label="Hora Fim" v-model="horaFimSelecioanda" required outlined clearable type="time"  />
        </v-col>
         <v-col >
          <v-text-field dense label="Dias de Tratamento" v-model="diasTratamentoSelecionado" required outlined clearable type="number"  />
        </v-col>
        <v-col >
          <v-autocomplete dense label="Frequencia" v-model="frequenciaSelecionada" :items="frequenciasFiltradas" item-value="id" item-text="nome" outlined :rules="requiredField" :loading="loadingGeral" />
        </v-col>
      </v-row>
      <v-row dense >
        
      </v-row>

      <v-row dense >
        <v-col >
          <v-autocomplete dense label="Dia da Semana" item-text="nome" v-model="diasSemanaSelecioando" :items="diasSemana" item-value="id" outlined :loading="loadingGeral" multiple chips >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="item" :key="index" close @click:close="removeViaAdministracao(item.id)">
                {{ item.nome }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        
      <!-- </v-row>


      <v-row dense> -->
        <v-col >
          <!-- Campo para exibir os horários selecionados -->
          <v-text-field dense label="Horários" v-model="horariosSelecionadosText" outlined readonly hide-details @click="toggleExpand">
            <template v-slot:append>
              <v-icon @click.stop="toggleExpand">
                {{ expandHorarios ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </template>
          </v-text-field>

          <!-- Expansão para exibir os horários -->
          <v-expand-transition>
            <v-card v-if="expandHorarios" class="mt-2 pa-2" outlined>
              <v-row dense>
                <v-col v-for="(horario) in horarios" :key="horario.id" cols="2">
                  <v-checkbox v-model="horariosSelecionados" :label="horario.nome" :value="horario.id" dense hide-details ></v-checkbox>
                </v-col>
              </v-row>
            </v-card>
          </v-expand-transition>
        </v-col>
      </v-row>

      <v-row dense class="mt-5">
        <v-text-field dense label="Observações / Orientações " v-model="obsSelecioanda" outlined />
      </v-row>
      <!-- Campos de anexos múltiplos -->

      <v-row class="justify-end" dense>
        <v-col class="text-end">
          <v-btn color="red" text class="mr-2" elevation="0" @click="limpar"> limpar Campos </v-btn>
          <ConfirmButton :loading="isSubmitting" color="success">Salvar</ConfirmButton>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template> 

<script>
import UtilsFunc from '../../../../../../service/utilsFunc';
import ConfirmButton from '../../../../../ConfirmButton.vue';
import api from "../../../../../../http";
import { mapGetters } from "vuex";

export default {
  name: "NovoMedicamento",
  props: {
    operadoraId: Number,
    onSubmit: Function,
    paciente: Number,
  },
  data: () =>
    UtilsFunc.withCRUDUtils({
      isLoading: true,
      requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório'],
      loadingGeral: false,
      loadingMedicamento: false,
      isFormValid: false,
      isSubmitting: false,
      loading: false,
      documentTypes: [],
      selectedDocumentType: null,
      medicamentos: [], 
      medicamentoCompelementar: [],
      quemfornece: [],
      quemforneceSelecionado: "", 
      diasSemanaSelecioando: "", 
      disponibilidadeOptions: [],
      diasSemana: [],
      frequencia: [],
      viaAdministracao: [],
      classe_terapeutica: [],
      disponibilidadeescolhida: null,
      medicamento: null,
      medicamento_complementar: null,
      frequenciaSelecionada: null,
      sos: null,
      dataInicioSelecioanda: null,
      dataFimSelecioanda: null,
      horaInicioSelecioanda: null,
      // horaFimSelecioanda: null,
      diasTratamentoSelecionado: null,
      quantidade_medicameneto: 1,
      hojeData: null,
      hojeDataPrevista: null,
      viaadministracaoSelecioando: null,
      viaAdministracaoFiltrada: [],
      obsSelecioanda: null,
      quantidadeTratamentoSelecionado: null, 
      expandHorarios: false, // Controla a exibição do painel
      horariosSelecionados: [], // IDs dos horários escolhidos
      horarios: [], // Lista completa dos horários vinda do backend
      quantidade_medicameneto_complementar: 1,
      aprazamento: [], // Apenas os medicamentos filtrados pela Classe Terapêutica
      grupo: [], // Apenas os medicamentos filtrados pela Classe Terapêutica
      aprazamentoSelecionado: null, // Apenas os medicamentos filtrados pela Classe Terapêutica
      grupoSelecionado: null, // Apenas os medicamentos filtrados pela Classe Terapêutica
      classe_terapeutica_selecionada: null, 
      statusOptions: [
        { label: "Sim", value: true },
        { label: "Não", value: false },
      ],
      selectedFiles: [],
      documentAttachments: [],
      totalDiasSemana: 0,
      qtdAplicacao: 0
    }),
  methods: {
    async getAprazamento() {
      this.loadingMedicamento = true;
      try {
        const { data } = await api.get("/pacientes/grupo_aprazamento/");
        this.aprazamento = data.filter(item => item.ativo );
        // Garantir que todos os medicamentos sejam listados inicialmente
      } catch (error) {
        console.error("❌ Erro ao carregar medicamentos:", error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Erro: ${error}`);
      } finally {
        this.loadingMedicamento = false;
      }
    },
    async getGrupoAprazamento() {
      this.loadingMedicamento = true;
      try {
        const { data } = await api.get("/pacientes/grupo/");
        this.grupo = data.filter(item => item.ativo );
        // Garantir que todos os medicamentos sejam listados inicialmente
      } catch (error) {
        console.error("❌ Erro ao carregar medicamentos:", error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Erro: ${error}`);
      } finally {
        this.loadingMedicamento = false;
      }
    },
    async getDiasSemana() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/uteis/dias_semana/`);
        this.diasSemana = data.filter(item => item.ativo);
        // console.log(this.diasSemana)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getHorarios() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/uteis/horarios/`);
        this.horarios = data.filter(item => item.ativo);
        // console.log(this.diasSemana)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getFrequencia() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/atendimentos/frequencias/`);
        this.frequencia = data
          .filter(item => item.ativo)
          .sort((a, b) => a.nome.localeCompare(b.nome));
        // console.log(this.frequencia)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }
       if (!this.dataFimSelecioanda && this.horaFimSelecioanda) {
         this.$toast.error("O Horário final só pode ser cadastrado com DATA FIM preenchido!");
        return;
      }
      
      this.isSubmitting = true;

      // ✅ Criamos um objeto apenas com as informações do medicamento principal
      const medicamentoPrincipal = {
        paciente: this.paciente,
        operadora: this.operadoraId,
        aprazamento: this.aprazamentoSelecionado,
        grupo_aprazamento: this.grupoSelecionado,
        frequencia : this.frequenciaSelecionada,
        dias_tratamento : this.diasTratamentoSelecionado,
        horario_administracao: this.horariosSelecionados,
        dias_semana : this.diasSemanaSelecioando,
        data_inicio: this.dataInicioSelecioanda,
        hora_inicio: this.horaInicioSelecioanda,
        data_fim: this.dataFimSelecioanda,
        hora_fim: this.horaFimSelecioanda,
        quem_prescreveu: this.userData.apelido,
        obs: this.obsSelecioanda,
      };
      
      // console.log('aqui')
      try {
          await this.onSubmit({ medicamentoPrincipal  });
        } finally {
          this.isSubmitting = false;
        }
       
    },


    atualizarMedicamentoSelecionado() {
      if (!this.medicamento) return;
      
      const medicamentoCompleto = this.medicamentos.find(med => med.id === this.medicamento);
      
      if (medicamentoCompleto) {
        this.medicamento = medicamentoCompleto; // Armazena o objeto completo
        this.atualizarViasAdministracao();
      }
    },
     atualizarViasAdministracao() {
      if (!this.medicamento || !this.medicamento.via_administracao) {
        this.viaAdministracaoFiltrada = []; // Se não houver medicamento ou vias, limpa a lista
      } else {
        this.viaAdministracaoFiltrada = this.medicamento.via_administracao.map(via => ({
          id: via.id,
          nome: via.nome,
          sigla: via.sigla
        }));
      }
    },
    limpar(){
      this.medicamento =null,
      this.medicamento_complementar =null,
      this.frequenciaSelecionada =null,
      this.sos =null,
      this.dataInicioSelecioanda =null,
      // this.dataFimSelecioanda =null,
      this.horaInicioSelecioanda =null,
      // this.horaFimSelecioanda =null,
      this.diasTratamentoSelecionado =null,
      this.diasSemanaSelecioando = [],
      this.hojeData =null,
      this.hojeDataPrevista =null,
      this.viaadministracaoSelecioando =null,
      this.viaAdministracaoFiltrada = null,
      this.quantidadeTratamentoSelecionado = null,
      this.obsSelecioanda =null,
      this.horariosDisponiveis =[], 
      this.horariosSelecionados =[],
      this.classe_terapeutica = [],
      this.quantidade_medicameneto_complementar = 1,
      this.quantidade_medicameneto = 1,
      this.selectedFiles = null
      this.hoaririosSelecioando = []
      this.selectedDocumentType = null,
      this.inicializar()
      
    },
    inicializar(){
      this.getAprazamento();
      this.getGrupoAprazamento()
      this.getHorarios();
      this.getDiasSemana()
      this.getFrequencia()




      const hoje = new Date();
      const amanha = new Date();
      amanha.setDate(hoje.getDate() + 1);
      const formatarData = (data) => {
        return data.getFullYear() + '-' + 
          String(data.getMonth() + 1).padStart(2, '0') + '-' + 
          String(data.getDate()).padStart(2, '0');
      };
      this.hojeData = formatarData(hoje);
      this.dataInicioSelecioanda = formatarData(hoje);
      

    },
   
    marcarHorariosAutomaticamente() {
    if (!this.horaInicioSelecioanda || !this.frequenciaSelecionada) return;

    const frequencia = this.frequencia.find(f => f.id === this.frequenciaSelecionada);
    if (!frequencia || frequencia.fator === undefined) return;

    // 💾 Reseta os horários antes de calcular
    this.horariosSelecionados = [];

    const fator = Math.floor(frequencia.fator || 1); // 🔢 Usa apenas a parte inteira do fator, ou assumi que é 1
   
    let horarios = [];

    let [hora, minuto] = this.horaInicioSelecioanda.split(':').map(Number);

    if (fator <= 1) {
      let horarioFormatado = `${String(hora).padStart(2, "0")}:${String(minuto).padStart(2, "0")}`;
      let horarioEncontrado = this.horarios.find(h => h.nome === horarioFormatado);
      if (horarioEncontrado) horarios.push(horarioEncontrado.id);
    } else {
      const intervalo = Math.floor(24 / fator);

      for (let i = 0; i < fator; i++) {
        let novaHora = (hora + (i * intervalo)) % 24;
        let novaHoraFormatada = `${String(novaHora).padStart(2, "0")}:${String(minuto).padStart(2, "0")}`;
        
        let horarioEncontrado = this.horarios.find(h => h.nome === novaHoraFormatada);
        if (horarioEncontrado) horarios.push(horarioEncontrado.id);
      }
    }

    this.horariosSelecionados = horarios.sort((a, b) => a - b);

  },

    marcarDiaDaSemanaAutomaticamente() {
      if (!this.frequenciaSelecionada) return;

      const frequenciasDiarias = [3, 4, 15, 16, 17, 18];
      const frequenciasQueMarcamUmDia = [12, 11, 10, 1, 14, 13];
      
      if (this.diasTratamentoSelecionado) {
          this.marcarDiasTratamento();
          return;
      }

      if (frequenciasDiarias.includes(this.frequenciaSelecionada)) {
          this.diasSemanaSelecioando = this.diasSemana.map(dia => dia.id);
          return;
      }

      if (frequenciasQueMarcamUmDia.includes(this.frequenciaSelecionada)) {
          const hoje = new Date();
          let diaSemanaAtual = hoje.getDay();
          if (diaSemanaAtual === 0 || diaSemanaAtual === 6) {
              diaSemanaAtual = 1;
          }
          this.diasSemanaSelecioando = [diaSemanaAtual];

          return;
      }
    },
    marcarDiasTratamento() {
      if (!this.diasTratamentoSelecionado) return;

      let quantidade = Math.min(this.diasTratamentoSelecionado, 7);
      let hoje = new Date();
      let diaAtual = hoje.getDay();
      diaAtual = diaAtual === 0 ? 7 : diaAtual; // Converte domingo de 0 para 7
      
      let diasSelecionados = [];
      let diasProcessados = 0;
      let proximoDia = diaAtual;
      
      // Continua adicionando dias até atingir a quantidade desejada
      while (diasProcessados < quantidade) {
          // Adiciona o dia atual se ainda não foi adicionado
          if (!diasSelecionados.includes(proximoDia)) {
              diasSelecionados.push(proximoDia);
              diasProcessados++;
          }
          
          // Move para o próximo dia
          proximoDia++;
          // Se passou de domingo (7), volta para segunda (1)
          if (proximoDia > 7) {
              proximoDia = 1;
          }
      }
      
      // Ordena os dias para garantir a sequência correta
      diasSelecionados.sort((a, b) => a - b);
      
      // Atualiza os dias selecionados
      this.diasSemanaSelecioando = diasSelecionados;

      // console.log("Dias selecionados:", diasSelecionados); // Para debug
    },
    marcarDiasTratamentoComFinaisDeSemana() {
      if (!this.diasTratamentoSelecionado) return;

      let quantidade = Math.min(this.diasTratamentoSelecionado, 7); // Limita a 7 dias, já que não podemos ter mais que isso
      let hoje = new Date();
      let diaAtual = hoje.getDay();
      diaAtual = diaAtual === 0 ? 7 : diaAtual; // Converte domingo de 0 para 7
      
      let diasSelecionados = [];
      
      // Primeiro, adicionamos o dia atual
      diasSelecionados.push(diaAtual);
      
      // Então, adicionamos os próximos dias necessários
      let diasRestantes = quantidade - 1;
      let proximoDia = diaAtual;
      
      while (diasRestantes > 0) {
          proximoDia++;
          if (proximoDia > 7) {
              proximoDia = 1; // Volta para segunda quando passar de domingo
          }
          
          if (!diasSelecionados.includes(proximoDia)) {
              diasSelecionados.push(proximoDia);
              diasRestantes--;
          }
      }
      
      // Ordena os dias
      diasSelecionados.sort((a, b) => a - b);
      
      // Atualiza os dias selecionados
      this.diasSemanaSelecioando = diasSelecionados;
    },  
    marcarDiasPorFrequencia(quantidadeDias) {
      let hoje = new Date();
      let diaAtual = hoje.getDay();
      diaAtual = diaAtual === 0 ? 7 : diaAtual; // Converte domingo de 0 para 7
      
      let diasDisponiveis = [1, 2, 3, 4, 5]; // Apenas dias úteis (seg a sex)
      let diasSelecionados = [];
      
      // Remove o dia atual da lista de dias disponíveis se for dia útil
      if (diaAtual <= 5) {
          diasDisponiveis = diasDisponiveis.filter(d => d !== diaAtual);
      }
      
      // Adiciona o dia atual se for dia útil
      if (diaAtual <= 5) {
          diasSelecionados.push(diaAtual);
      }
      
      // Seleciona os dias restantes necessários
      while (diasSelecionados.length < quantidadeDias && diasDisponiveis.length > 0) {
          // Calcula o intervalo ideal entre os dias
          const intervalo = Math.floor(5 / quantidadeDias);
          
          // Encontra o próximo dia adequado
          let proximoDia = diaAtual + intervalo;
          while (proximoDia > 5) proximoDia -= 5;
          
          // Se o dia já estiver selecionado, tenta o próximo
          while (diasSelecionados.includes(proximoDia) && diasDisponiveis.length > 0) {
              proximoDia = proximoDia + 1 > 5 ? 1 : proximoDia + 1;
          }
          
          if (!diasSelecionados.includes(proximoDia)) {
              diasSelecionados.push(proximoDia);
              diasDisponiveis = diasDisponiveis.filter(d => d !== proximoDia);
          }
          
          diaAtual = proximoDia;
      }
      
      // Ordena os dias selecionados
      diasSelecionados.sort((a, b) => a - b);
      
      // Atualiza os dias selecionados
      this.diasSemanaSelecioando = diasSelecionados;
  
      
      // console.log("Dias selecionados para frequência:", diasSelecionados);
    },        
    removeViaAdministracao(id) {
      this.diasSemanaSelecioando = this.diasSemanaSelecioando.filter(via => via !== id);
    },
    lista() {
      return this.userData || {}
    },
    toggleExpand() {
      this.expandHorarios = !this.expandHorarios;
    },
    removeHorario(id) {
      this.horariosSelecionados = this.horariosSelecionados.filter(h => h !== id);
    },
    getHorarioNome(id) {
      const horario = this.horarios.find(h => h.id === id);
      return horario ? horario.nome : 'Desconhecido';
    },
    calcularDataFim() {
      if (!this.dataInicioSelecioanda || !this.totalDiasSemana || !this.qtdAplicacao) return;

      const dataInicio = new Date(this.dataInicioSelecioanda);
      
      // console.log('this.totalDiasSemana', this.totalDiasSemana)
      // console.log('this.qtdAplicacao', this.qtdAplicacao)
      // Soma os dias necessários
      const diasSomados = 
        this.totalDiasSemana 
        // + this.qtdAplicacao 
        - 1;
      dataInicio.setDate(dataInicio.getDate() + diasSomados);

      // Atualiza a variável com a nova data formatada
      this.dataFimSelecioanda = dataInicio.toISOString().split('T')[0];

      // console.log("📅 Nova Data Fim:", this.dataFimSelecioanda);
    },
  },
  components: { ConfirmButton },
  computed: {
     ...mapGetters(['companyData', 'userData']),
    viaAdministracaoText() {
      return (item) => {
          return `${item.nome} - ${item.sigla} `;
      }
    },
    prescricaoText() {
      return this.medicamento && this.medicamento.apresentacao_prescricao
        ? `${this.medicamento.apresentacao_prescricao.nome} - ${this.medicamento.apresentacao_prescricao.abreviatura}`
        : "Sem prescrição definida";
    },
    exibirObsViaSonda() {
      return (
        this.medicamento &&
        this.medicamento.via_sonda &&
        this.medicamento.via_sonda.nome?.toLowerCase() === "sim" &&
        this.medicamento.obs_via_sonda
      );
    },
    altorisco() {
      return (
        this.medicamento &&
        this.medicamento.alto_risco === true
      );
    },
    interacaoMedicamento() {
      return (
        this.medicamento &&
        this.medicamento.interacao === true
      );
    },
    padrao() {
      return this.medicamento && this.medicamento.padrao === true;
    },
    // ✅ Computed para filtrar as frequências dependendo se Dias de Tratamento está preenchido ou não
    frequenciasFiltradas() {
        const frequenciasPermitidas = [3, 4, 15, 16, 17]; // ✅ IDs permitidos se dias de tratamento estiverem preenchidos

        if (this.diasTratamentoSelecionado || this.quantidadeTratamentoSelecionado) {
            // Retorna apenas as frequências permitidas
            return this.frequencia.filter(f => frequenciasPermitidas.includes(f.id));
        }
        return this.frequencia; // Se não houver "Dias de Tratamento", mostra todas as frequências
    },
    horariosSelecionadosText() {
      return this.horariosSelecionados.length
        ? this.horariosSelecionados.map(id => this.getHorarioNome(id)).join(', ')
        : 'Selecione os horários';
    },
    
    
  },
  watch: {
  
    // Atualiza os horários quando a "Hora Início" mudar
    horaInicioSelecioanda() {
      this.marcarHorariosAutomaticamente();
    },
    
    diasTratamentoSelecionado(newValue) {
      if (newValue) {
          this.marcarDiasTratamento();
          this.quantidadeTratamentoSelecionado = null; // Zera quantidade de administrações
          
          const frequenciasPermitidas = [3, 4, 15, 16, 17];
          if (this.frequenciaSelecionada && !frequenciasPermitidas.includes(this.frequenciaSelecionada)) {
              this.frequenciaSelecionada = null;
          }
      } else {
          this.frequenciaSelecionada = null;
          this.diasSemanaSelecioando = []; // Limpa os dias selecionados quando não houver dias de tratamento
      }
      if (this.diasTratamentoSelecionado) {
        const dataInicio = new Date(this.dataInicioSelecioanda);
        dataInicio.setDate(dataInicio.getDate() + parseInt(newValue) -1); // Soma os dias informados
        this.dataFimSelecioanda = dataInicio.toISOString().split('T')[0]; // Formata no padrão YYYY-MM-DD
      } else {
        this.dataFimSelecioanda = null; // Reseta se não houver dados válidos
      }
    },
    quantidadeTratamentoSelecionado(newValue) {
      if (newValue) {
        this.diasTratamentoSelecionado = null; // Zera dias de tratamento
        
        // Reset previous selections
        this.diasSemanaSelecioando = [];
        
        // Only proceed if we have both quantity and frequency
        if (this.frequenciaSelecionada) {
          // console.log('aqui...')
          const frequencia = this.frequencia.find(f => f.id === this.frequenciaSelecionada);
          if (frequencia) {
            // Calculate how many days we need based on quantity and frequency
            const administracoesPorDia = frequencia.qt || 1; // Get daily administrations from frequency
            const diasNecessarios = Math.ceil(newValue / administracoesPorDia);
            
            // Limit to 7 days maximum
            const diasAMarcar = Math.min(diasNecessarios, 7);
            
            // Get current day
            let hoje = new Date();
            let diaAtual = hoje.getDay();
            diaAtual = diaAtual === 0 ? 7 : diaAtual; // Convert Sunday from 0 to 7
            
            let diasSelecionados = [];
            let proximoDia = diaAtual;
            
            // Select required number of days
            for (let i = 0; i < diasAMarcar; i++) {
              if (!diasSelecionados.includes(proximoDia)) {
                diasSelecionados.push(proximoDia);
              }
              
              // Move to next day
              proximoDia++;
              if (proximoDia > 7) proximoDia = 1;
            }
            
            // Sort days for consistent display
            diasSelecionados.sort((a, b) => a - b);
            
            // Update selected days
            this.diasSemanaSelecioando = diasSelecionados;
            

            // Update hours based on frequency
            this.marcarHorariosAutomaticamente();
          }
        }
        
        // Update available frequencies based on quantity
        const frequenciasPermitidas = [3, 4, 15, 16, 17];
        if (this.frequenciaSelecionada && !frequenciasPermitidas.includes(this.frequenciaSelecionada)) {
          this.frequenciaSelecionada = null;
        }
      } else {
        // Reset everything if quantity is cleared
        this.frequenciaSelecionada = null;
        this.diasSemanaSelecioando = [];
        this.horariosSelecionados = [];
      }
      this.qtdAplicacao = newValue.length || 0;
      // console.log("📌 Total de qtd Aplicacaoes:", this.qtdAplicacao);
    },

    // Add a new watcher for frequency when quantity is set
    frequenciaSelecionada(newValue) {
      if (this.quantidadeTratamentoSelecionado && newValue) {
        // Recalculate days when frequency changes with quantity set
        const frequencia = this.frequencia.find(f => f.id === newValue);
        if (frequencia) {
          const administracoesPorDia = frequencia.qt || 1;
          const diasNecessarios = Math.ceil(this.quantidadeTratamentoSelecionado / administracoesPorDia);
          const diasAMarcar = Math.min(diasNecessarios, 7);
          
          let hoje = new Date();
          let diaAtual = hoje.getDay();
          diaAtual = diaAtual === 0 ? 7 : diaAtual;
          
          let diasSelecionados = [];
          let proximoDia = diaAtual;
          
          for (let i = 0; i < diasAMarcar; i++) {
            if (!diasSelecionados.includes(proximoDia)) {
              diasSelecionados.push(proximoDia);
            }
            proximoDia++;
            if (proximoDia > 7) proximoDia = 1;
          }
          
          diasSelecionados.sort((a, b) => a - b);
          this.diasSemanaSelecioando = diasSelecionados;
          // Update hours for the new frequency
          // console.log(this.diasSemanaSelecioando.length)
          
          this.marcarHorariosAutomaticamente();
        }
      } else {
        // Original frequency behavior when quantity is not set
        if (!newValue) return;
        
        this.diasSemanaSelecioando = [];
        
        const frequenciasSemanais = {
          2: 2,
          5: 3,
          8: 4,
          9: 5
        };
        
        if (frequenciasSemanais[newValue]) {
          this.marcarDiasPorFrequencia(frequenciasSemanais[newValue]);
        } else {
          this.marcarHorariosAutomaticamente();
          this.marcarDiaDaSemanaAutomaticamente();
        }
      }
    },
    diasSemanaSelecioando(newValue) {
      this.totalDiasSemana = newValue.length || 0;
      // console.log("📌 Total de dias selecionados:", this.totalDiasSemana);
    },
    totalDiasSemana() {
    this.calcularDataFim();
    },
    qtdAplicacao() {
      this.calcularDataFim();
    },
    dataInicioSelecioanda() {
      this.calcularDataFim();
    }

  },
  mounted() {
    this.inicializar()
  },
};
</script>

<style scoped>
.drop-zone {
  border: 2px dashed #ccc;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.drop-zone:hover {
  background-color: #f9f9f9;
}
.drop-zone-text {
  font-size: 0.8rem;
  color: #888;
  margin-top: 5px;
}
</style>
