<template>
  <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getPDF()"
    :loading="loading">
    <v-icon left>mdi-printer</v-icon>
    Declaração Histórico de atendimento 
  </v-btn>
</template>

<script>
import api from "@/http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { mapGetters } from 'vuex';
import UtilsPDF from '../../../service/utilsPDF';
import Assinatura from "../../../assets/Assinatura_Flavio_Andrade.png";

export default {
  name: "ContratoPaciente",
  data() {
    return {
      paciente: null,
      loading: false,
      evolcuoes: [],
      menorEvolucao: null,
      maiorEvolucao: null,
      planoTerapeuticoData: [],
      modalidadeMap: {
        1: "Internação Domiciliar 06 horas",
        2: "Sistema de Atenção Domiciliar - SAD",
        3: "Internação Domiciliar 12 horas",
        4: "Internação Domiciliar 24 horas",
        5: "Internação Domiciliar 24 horas com Ventilação Mecânica",
        10: "Tratamento Eletivo Domicilair - TED",
        11: "Locação de Equipamento",
        // Adicione outros mapeamentos conforme necessário
      }
    };
  },
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
    username: String
  },
  methods: {

    async getPDF() {
      this.loading = true;

      try {
        // Verificação do campo data_atendimento
        const hasInvalidDate = this.selectedBudgets.some(
          budget => !budget.data_atendimento
        );

        if (hasInvalidDate) {
          this.$toast.info("A data de atendimento não está cadastrada. O relatório não será gerado.");
          this.loading = false;
          return;
        }

        if (!this.paciente) {
          await this.getPaciente();
        }
        await this.getEvolucoes()

        // Verificação do endereço após obter os dados do paciente
        const hasInvalidAddress = this.paciente.some(p => !p.endereco);

        if (hasInvalidAddress) {
          this.$toast.info("O endereço não está cadastrado. O relatório não será gerado.");
          this.loading = false;
          return;
        }

        this.$toast.success("O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.");
        // Gere o PDF e salve-o, esperando pelo documento assinado.
        const doc = this.generatePDF();
        await this.saveAndSignPDF(doc);
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados:", error);
      } finally {
        this.loading = false;
      }
    },
    generatePDF() {
      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);
      this.addContractText(doc);
      return doc; // Retorna o objeto doc
    },
    async saveAndSignPDF(doc) {
      const formattedName = String(this.selectedBudgets[0].nome).replace(/\s/g, '_') // troca os espaços por underline

      const filename = `Declaracao_Historico_de_Atendimento_${formattedName}_${moment().format('YYYYMMDD_HHmmss')}.pdf`;

      const pdfBuffer = doc.output('arraybuffer');
      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });
      const formData = new FormData();
      formData.append("file", pdfBlob, filename);
      formData.append("filename", filename);


      // Envia para assinatura
      const { data: signedForm } = await api.post('multi_empresas/sign-pdf/', formData);

      const addHttps = signedForm.path.replace('http://', 'https://')
      const signedPDFBlob = await fetch(addHttps).then(r => r.blob())

      // Salve o PDF no servidor como um documento do paciente. Está salvando antes de assinar
      await this.saveDocument(signedPDFBlob, filename);

      const signedPdfUrl = signedForm.path;

      this.download(signedPdfUrl, filename);
      this.$toast.success('O download do relatório foi realizado com sucesso!');
    },
    async saveDocument(pdfBlob, filename) {
      const formData = new FormData();
      formData.append("arquivo", pdfBlob, filename);
      formData.append("paciente", this.selectedBudgets[0].id);
      formData.append("tipodoc", 30);

      try {
        await api.post('pacientes/docs/', formData);
        this.$toast.success('O relatório foi salvo nos documentos do paciente.');
      } catch (error) {
        this.$toast.error('Erro ao salvar o relatório nos documentos do paciente.', error);
      }
    },
    drawHeader(doc) {
      try {
        UtilsPDF.logomarcaGrande(doc);
        doc.addImage(Assinatura, 90, 210, 30, 30);
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF:", error);
      }

      doc.setFont("helvetica", "bold");
      doc.setFontSize(18);
      doc.text("São Luís Home Care", 105, 27, { align: "center" });
      doc.setFontSize(16);
      doc.text(`DECLARAÇÃO HISTÓRICO DE ATENDIMENTO`, 105, 53, { align: "center" });
    },
    addContractText(doc) {
      const paciente = this.paciente[0];
      const endereco = paciente.endereco ? `${paciente.endereco.logradouro}, ${paciente.endereco.nr}, ${paciente.endereco.bairro}, ${paciente.endereco.cidade} - ${paciente.endereco.estado} - CEP: ${paciente.endereco.cep}` : 'Endereço não cadastrado';
      let planoTerapeutico = '';
       if (
          this.planoTerapeuticoData &&
          this.planoTerapeuticoData.length > 0 &&
          this.planoTerapeuticoData[0].procedimentos &&
          this.planoTerapeuticoData[0].procedimentos.length > 0
        ) {
          planoTerapeutico = this.planoTerapeuticoData[0].procedimentos.map(item => {
            return `${item.procedimento} - ${item.frequencia}`;
          }).join(', ');
        } else {
          planoTerapeutico = 'Não Possui atendimento de Equipe Multidisciplinar';
        }



      const usuario = this.username
      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");
      const budget = this.selectedBudgets[0];
      const startY = 70;
      const cpf = budget.cpf ? `CPF Nº ${budget.cpf},` : '';
      const rg = budget.rg ? `RG Nº ${budget.rg},` : '';
      // Inverter o mapa para buscar por valor
      const modalidadeDoPlano = this.planoTerapeuticoData.length > 0 ? this.planoTerapeuticoData[0].modalidade : null;
      let modalidadeTexto = 'não cadastrada';

      if (modalidadeDoPlano) {
        const modalidadeInvertida = Object.entries(this.modalidadeMap).find(
          ([, value]) => value.toLowerCase().includes(modalidadeDoPlano.toLowerCase())
        );
        modalidadeTexto = modalidadeInvertida ? modalidadeInvertida[1] : modalidadeDoPlano;
      }

      const dataNascimento = budget.data_nascimento ? UtilsPDF.formatDate(budget.data_nascimento) : 'não cadastrada';
      const dataAtendimento = this.menorEvolucao.timestamp ? UtilsPDF.formatDate(this.menorEvolucao.timestamp) : 'não cadastrada';
      const fimAtendimento = this.maiorEvolucao.timestamp ? UtilsPDF.formatDate(this.maiorEvolucao.timestamp) : 'não cadastrada';
      const operadora = budget.operadora.nome ? budget.operadora.nome : 'não cadastrada'

      doc.setFontSize(14);
      doc.setFont("helvetica", "normal");

      const contractText = `
                  A Empresa Assistência Domiciliar Andrade LTDA, pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 22.121.808/0001-60, com sede na Rua Projetada Interna , nº 04, Araçagy, São José de Ribamar-MA, CEP: 65.125-253, por intermédio de seu representante legal o Sr. Flávio Roberto Melo de Andrade, inscrito sob o CPF nº 787.501.993-15, vem por meio deste DECLARAR que atendeu o(a) Sr.(a) ${budget.nome}, ${cpf} ${rg} nascido (a) em ${dataNascimento}, residente na ${endereco}. 

                  O(A) paciente iniciou os atendimentos nesta empresa no dia ${dataAtendimento}, pela contratante (operadora) ${operadora}.

                  O último atendimento foi realizado no dia ${fimAtendimento}
                  O último plano terapêutico era composto por: ${planoTerapeutico} na modadlide ${modalidadeTexto}. 

                  

                                              São José de Ribamar, ${UtilsPDF.formatDate(currentDate)} às ${hour}.



                                             ________________________________________
                                                ASSISTÊNCIA DOMICILIAR ANDRADE LTDA
                                                          CNPJ Nº 22.121.808/0001-60   
               
      `;

      const usuariotexto = `Documento gerado por: ${usuario}`;

      doc.setFontSize(12);
      const lines = doc.splitTextToSize(contractText, 190);
      let currentY = startY;

      // Adicionar texto do contrato
      lines.forEach(line => {
        doc.text(line, 10, currentY);
        currentY += 7;
      });

      // Adicionar texto do usuário no final da página
      doc.setFontSize(6);
      const pageWidth = doc.internal.pageSize.width;
      const textWidth = doc.getTextWidth(usuariotexto);
      const xPosition = pageWidth - textWidth - 10;
      currentY = doc.internal.pageSize.height - 23;
      doc.text(usuariotexto, xPosition, currentY);

      // Adicionar números de página
      UtilsPDF.addPageNumbers(doc);
    },
    async getPaciente() {
      try {
        const pacienteId = this.selectedBudgets[0].id;
        const { data } = await api.get(`/pacientes/detalhes/?paciente=${pacienteId}&empresa=1`);
        this.paciente = data;
        // console.log(this.paciente)
      } catch (error) {
        this.$toast.error("Erro ao buscar os dados do Paciente (getPaciente):", error);
        throw error;  // Garante que a função `getPDF` saiba que houve um erro.
      }
    },
    async getEvolucoes() {
      try {
        const pacienteId = this.selectedBudgets[0].id;
        const { data } = await api.get(`atendimentos/evolucoes/?paciente=${pacienteId}&ativo=true`);
        this.evolcuoes = data.filter(item => item.ativo && item.assinatura );
        if (this.evolcuoes.length > 0) {
          this.menorEvolucao = this.evolcuoes.reduce((min, evo) =>
            new Date(evo.timestamp) < new Date(min.timestamp) ? evo : min
          );

          this.maiorEvolucao = this.evolcuoes.reduce((max, evo) =>
            new Date(evo.timestamp) > new Date(max.timestamp) ? evo : max
          );
        } else {
          this.menorEvolucao = null;
          this.maiorEvolucao = null;
        }
        const response = await api.get(`atendimentos/plano-terapeutico_ultimo/?orcamento=${this.maiorEvolucao.sessao.id}`)
        this.planoTerapeuticoData = response.data; // <- salva a resposta

        // console.log('Evoluções:', this.evolcuoes);
        // console.log('Mais antiga:', this.menorEvolucao);
        // console.log('Mais recente:', this.maiorEvolucao);
        // console.log('Plano Terapeutico', this.planoTerapeuticoData);
      } catch (error) {
        this.$toast.error("Erro ao buscar os dados do Paciente (getPaciente):", error);
        throw error;  // Garante que a função `getPDF` saiba que houve um erro.
      }
    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl
      const res = await fetch(httpsurl)
      const buffer = await res.blob()
      const url = URL.createObjectURL(buffer)
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
    lista() {
      return this.userData || {}
    },
  },
  computer: { ...mapGetters(['userData']), },

  mounted() {
    this.getPaciente();
    this.lista();
    this.getEvolucoes()
  }
};
</script>
