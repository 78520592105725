<template>
  <v-container fluid>
    <!-- {{item}} -->
    <v-row dense>
      <!-- Primeira Coluna -->
      <v-col cols="2">
        <h2><b>Dados Gerais</b></h2>
        <p><b>Classe Terapêutica:</b> {{ item?.medicamento?.classe_terapeutica?.nome }}</p>
        <p><b>Tarja:</b> {{ item.medicamento?.tarja?.nome }}</p>
        <p><b>Via de Administração:</b> {{ item.via_administracao?.nome }}</p>
        <p><b>Data de Início:</b> {{ formatDate(item.data_inicio) }}</p>
        <p><b>Data de Fim:</b> {{ item.data_fim ? formatDate(item.data_fim) : 'Indeterminado' }}</p>
        
      </v-col>
      <!-- Segunda Coluna -->
      <v-col cols="2">
        <p></p>
        <p></p>
        <p><b>SOS:</b> {{ item.sos ? 'Sim' : 'Não' }}</p>
        <p><b>Alto Risco:</b> {{ item.medicamento.alto_risco ? 'Sim' : 'Não' }} </p>
        <p v-if="item.medicamento.alto_risco"><b>Qual o Risco:</b> {{ item.medicamento.qual_risco }}</p>
        <p><b>Responsável pelo Fornecimento:</b> {{ item.quem_fornece?.nome }}</p>
        <p><b>Risco de Interação:</b> {{ item.medicamento.interacao ? 'Sim' : 'Não' }} </p>
        <p v-if="item.medicamento.interacao"><b>Qual o Risco de Interação:</b> {{ item.medicamento.obs_interacao }}</p>
        <p><b>Disponibilidade:</b> {{ item.disponibilidade?.nome }}</p>
        <p v-if="item.sos"><b>Quantidade Medicamento:</b> {{ item.quantidade_medicameneto }} - {{ item.medicamento.apresentacao_prescricao.abreviatura}}</p>
      </v-col>
      <!-- Terceira Coluna -->
      <v-col cols="3" v-if="!item.sos">
        <v-col  v-if="item.id_medicamento_referencia_complementar === null">
          <h3><b>Frequência e Administração</b></h3>
          <p><b>Frequência:</b> {{ item.frequencia ? item.frequencia?.nome : 'Não especificado' }}</p>
          <p v-if="item.quantidade_tratamento > 0"><b>Quantidade de Administração:</b> {{ item.quantidade_tratamento || 'Não especificado' }}</p>
          <p v-if="item.dias_tratamento > 0"><b>Dias de Tratamento:</b> {{ item.dias_tratamento || 'Não especificado' }}</p>
          <p><b>Dias da Semana:</b> 
            <span v-if="item.dias_semana.length > 0">
              {{ item.dias_semana.map(dia => dia.nome).join(', ') }}
            </span>
            <span v-else>Não especificado</span>
          </p>
          <p><b>Horários de Adminsitração:</b> 
            <span v-if="item.horario_administracao.length > 0">
              {{ item.horario_administracao.map(dia => dia.nome).join(', ') }}
            </span>
            <span v-else>Não especificado</span>
          </p>
          <p><b>Quantidade Medicamento:</b> {{ item.quantidade_medicameneto }}  {{ item.medicamento.apresentacao_prescricao.abreviatura}}</p>
          <p v-if="item.medicamento.tipo.id === 3"><b >Uso em Dieta Enteral:</b> {{ item.dieta_enteral ? 'Sim' : 'Não' }}</p>
        </v-col>
        <v-col v-else>
          <v-col >
            <h3><b>Medicamento Associado a Administração  </b></h3>
            <h3><b>da Medicação de ID: {{item.id_medicamento_referencia_complementar}} </b></h3>
          </v-col>
        </v-col>
      </v-col>
   
      <v-col cols="3" >
        <p><b>Pode Administrar Via Sonda:</b> {{ item.medicamento.via_sonda.nome }} </p>
        <p v-if="item.medicamento.via_sonda.id === 1"><b>Orientações Via Sonda:</b> {{ item.medicamento.obs_via_sonda }}</p>
        <p><b>Observações / Orientações para Prescrição / Administração:</b> {{ item.obs || 'Nenhuma' }}</p>
        <p><b>Quem Adicionou essa Medicação:</b> {{ item.quem_prescreveu || 'Não Informado' }}</p>
        <p v-if="item.quem_modificou"><b>Quem Modificou essa Medicação:</b> {{ item.quem_modificou || 'Não Informado' }}</p>
      </v-col>
    
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: "IndexRevisado",
  props: {
    item: Object,
  },
  data: () => ({
    isPageLoading: true,
  }),
  methods: {
    formatDate(dateString) {
      if (!dateString) return '';
      const [year, month, day] = dateString.split('-');
      return `${day}/${month}/${year}`;
    },
  },
  computed: {
    primeiraMetadeHorarios() {
      if (!Array.isArray(this.item.horarios_administracao)) return [];
      const metade = Math.ceil(this.item.horarios_administracao.length / 2);
      return this.item.horarios_administracao.slice(0, metade);
    },
    segundaMetadeHorarios() {
      if (!Array.isArray(this.item.horarios_administracao)) return [];
      const metade = Math.ceil(this.item.horarios_administracao.length / 2);
      return this.item.horarios_administracao.slice(metade);
    }
  },
  mounted() {
    this.isPageLoading = false;
  },
};
</script>

<style scoped>
.text {
  color: red;
}
</style>
