<template>
  <div>
    <v-form @submit.prevent="localOnSubmit" ref="formRef">
      <!-- <p>{{ medicamento }}</p> -->
      <v-row dense>
        <v-col cols="3" >
          <v-select dense label="Disponibilidade" :items="disponibilidadeOptions" item-text="nome" item-value="id" v-model="medicamento.disponibilidade.id" outlined  :rules="requiredField" />
        </v-col>
        <v-col cols="1">
          <v-text-field outlined :value="medicamento.id" label="ID" dense disabled></v-text-field>
        </v-col>
        <v-col>
          <v-autocomplete  dense  label="Medicamento" v-model="medicamento.medicamento"  :items="medicamentosFiltrados"  item-text="nome"  item-value="id"  outlined  :rules="requiredField"  :loading="loadingMedicamento" disabled/>
        </v-col>
        <v-col v-if="medicamento.medicamento" cols="2">
          <v-tooltip top >
            <template v-slot:activator="{ on, attrs }">
              <v-alert 
                v-bind="attrs" 
                v-on="on" 
                :type="medicamento.medicamento.padrao ? 'success' : 'warning'" 
                dense 
                class="text-body-2 text-center"
                style="font-size: 8px; padding: 7px;"
              >
                {{ medicamento.medicamento.padrao ? 'PADRÃO' : 'NÃO PADRÃO' }}
              </v-alert>
            </template>
            <span>{{ medicamento.medicamento.padrao ? 'Este medicamento é padronizado na empresa..' : 'Este medicamento não é padronizado na empresa. Provavelmente não temos em estoque.' }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
     
      <v-row dense v-if="medicamento.medicamento.via_sonda.id === 1">
        <v-col cols="12">
          <v-alert type="info" dense>
          Administrado Via Sonda - OBS: {{ medicamento.medicamento.obs_via_sonda }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row dense v-if="medicamento.medicamento.alto_risco">
        <v-col cols="12">
          <v-alert type="error" dense>
          Medicamento Alto Risco - OBS: {{medicamento.medicamento.qual_risco }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row dense v-if="interacaoMedicamento">
        <v-col cols="12">
          <v-alert type="warning" dense>
          Risco de Interação - OBS: {{ medicamento.obs_interacao }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="2" v-if="medicamento">
          <v-text-field dense label="Como é prescrito" v-model="medicamento.medicamento.apresentacao_prescricao.abreviatura" outlined readonly ></v-text-field>
        </v-col>
        <v-col cols="1"  >
          <v-text-field dense  label="Qtd." type="number" outlined v-model="medicamento.quantidade_medicameneto" :rules="requiredField" ></v-text-field>
        </v-col>
         <v-col v-if="medicamento.via_administracao" :disabled="medicamento.disponibilidade.id === 6">
          <v-autocomplete dense label="Via de Administração" v-model="medicamento.via_administracao" :items="viasPermitidas" item-value="id" :item-text="viaAdministracaoText" outlined :rules="requiredField" :loading="loadingGeral" />
        </v-col>
        <v-col cols="2">
          <v-autocomplete dense label="Responsável Fornecimento" v-model="medicamento.quem_fornece.id" :items="quemfornece" item-value="id" item-text="nome" outlined :rules="requiredField" :loading="loadingGeral" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col >
          <v-text-field dense label="Data Inicio" v-model="medicamento.data_inicio" required outlined clearable type="date" :rules="requiredField" :disabled="medicamento.disponibilidade.id === 6" />
        </v-col>
        <v-col v-if="sos === false">
          <v-text-field dense label="Hora Início" v-model="medicamento.hora_inicio" required outlined clearable type="time" :rules="requiredField" />
        </v-col>
        <v-col >
          <v-text-field dense label="Data Fim" v-model="medicamento.data_fim" outlined clearable type="date" />
        </v-col>
        <v-col v-if="sos === false">
          <v-text-field dense label="Hora Fim" v-model="medicamento.hora_fim" required outlined clearable type="time"  />
        </v-col>
        <v-col v-if="sos === false  ">
          <v-text-field dense label="Dias de Tratamento" v-model="medicamento.dias_tratamento" required outlined clearable type="number" :disabled="medicamento.disponibilidade.id === 6 || medicamento.dias_tratamento > 0 || !medicamento.dias_tratamento" />
        </v-col>
        <v-col v-if="sos === false ">
          <v-text-field dense label="Quantas Administrações" v-model="medicamento.quantidade_tratamento" required outlined clearable type="number" :disabled="medicamento.disponibilidade.id === 6 || medicamento.quantidade_medicameneto > 0 ||!medicamento.quantidade_medicameneto" />
        </v-col>
        <v-col v-if="sos === false && medicamento.frequencia">
          <v-autocomplete dense label="Frequencia" v-model="medicamento.frequencia.id" :items="frequenciasFiltradas" item-value="id" item-text="nome" outlined :rules="requiredField" :loading="loadingGeral" :disabled="medicamento.disponibilidade.id === 6"/>
        </v-col>
      </v-row>
      <v-row dense >
        
      </v-row>

      <v-row dense >
        <v-col v-if="sos === false " :disabled="medicamento.disponibilidade.id === 6">
          <v-autocomplete dense label="Dia da Semana" item-text="nome" v-model="medicamento.dias_semana" :items="diasSemana" item-value="id" outlined :loading="loadingGeral" multiple chips >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="item" :key="index" close @click:close="removeViaAdministracao(item.id)">
                {{ item.nome }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
       
      <!-- </v-row>

      <v-row dense > -->
        <v-col v-if="sos === false && medicamento.horario_administracao.length > 0">
          <!-- Campo para exibir os horários selecionados -->
          <v-text-field dense label="Horários" v-model="horariosSelecionadosText" outlined readonly hide-details @click="toggleExpand">
            <template v-slot:append>
              <v-icon @click.stop="toggleExpand">
                {{ expandHorarios ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </template>
          </v-text-field>

          <!-- Expansão para exibir os horários -->
          <v-expand-transition>
            <v-card v-if="expandHorarios" class="mt-2 pa-2" outlined>
              <v-row dense>
                <v-col v-for="(horario) in horarios" :key="horario.id" cols="2">
                  <v-checkbox v-model="horariosSelecionados" :label="horario.nome" :value="horario.id" dense hide-details ></v-checkbox>
                </v-col>
              </v-row>
            </v-card>
          </v-expand-transition>
        </v-col>
      </v-row>




      <v-row dense class="mt-5">
      <v-col  v-if="medicamento.disponibilidade.id === 2" cols="3">
        <v-text-field dense label="Data de Inclusão no Orçaemnto" v-model="medicamento.data_orcamento" required outlined clearable type="date" :rules="requiredField" :disabled="medicamento.disponibilidade.id === 6"/>
      </v-col>
      <v-col  v-if="medicamento.disponibilidade.id === 3" cols="3">
        <v-text-field dense label="Data Requisição" v-model="hojeData" required outlined clearable type="date" :rules="requiredField" :disabled="medicamento.disponibilidade.id === 6"/>
      </v-col>
      <v-col  v-if="medicamento.disponibilidade.id === 3" cols="3">
        <v-text-field dense label="Data para Entrega" v-model="hojeDataPrevista" required outlined clearable type="date" :rules="requiredField" :disabled="medicamento.disponibilidade.id === 6"/>
      </v-col>
      </v-row>
      <v-row dense class="mt-5">
        <v-text-field dense label="Observações / Orientações para Prescrição / Administração" v-model="medicamento.obs" outlined />
      </v-row>
    <v-row dense>
      <v-btn-toggle v-model="medicamento.ativo" mandatory class="white--text">
        <v-btn :color="!medicamento.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="medicamento.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
    </v-row>
      <v-row class="justify-end" dense>
        <v-col class="text-end">
          <ConfirmButton :loading="isSubmitting" color="success">Salvar</ConfirmButton>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template> 

<script>
import UtilsFunc from '../../../../../../service/utilsFunc';
import ConfirmButton from '../../../../../ConfirmButton.vue';
import api from "../../../../../../http";
import { mapGetters } from "vuex";


export default {
  name: "EditarMedicamento",
  props: {
    medicamento:  { type: Object, default: null },
    onSubmit: Function, 

  },
  data: () =>
    UtilsFunc.withCRUDUtils({
      isLoading: true,
      requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório'],
      loadingGeral: false,
      loadingMedicamento: false,
      isFormValid: false,
      isSubmitting: false,
      loading: false,
      medicamentos: [], 
      // medicamentoCompelementar: [],
      quemfornece: [],
      quemforneceSelecionado: "", 
      diasSemanaSelecioando: "", 
      disponibilidadeOptions: [],
      diasSemana: [],
      frequencia: [],
      viaAdministracao: [],
      classe_terapeutica: [],

      disponibilidadeescolhida: null,
      // medicamento: null,
      medicamento_complementar: null,
      // frequenciaSelecionada: null,
      sos: null,
      dataInicioSelecioanda: null,
      // dataFimSelecioanda: null,
      horaInicioSelecioanda: null,
      // horaFimSelecioanda: null,
      diasTratamentoSelecionado: null,
      quantidade_medicameneto: 1,
      hojeData: null,
      hojeDataPrevista: null,
      viaadministracaoSelecioando: null,
      viaAdministracaoFiltrada: [],
      medicamentosPaciente: [],
      obsSelecioanda: null,
      quantidadeTratamentoSelecionado: null, 
      expandHorarios: false, // Controla a exibição do painel
      horariosSelecionados: [], // IDs dos horários escolhidos
      horarios: [], // Lista completa dos horários vinda do backend
      quantidade_medicameneto_complementar: 1,
      medicamentosFiltrados: [], // Apenas os medicamentos filtrados pela Classe Terapêutica
      classe_terapeutica_selecionada: null, 
      statusOptions: [
        { label: "Sim", value: true },
        { label: "Não", value: false },
      ],
      medicamentoOriginal: null,
    }),
  methods: {
    async getMedicamento() {
      this.loadingMedicamento = true;
      try {
        const { data } = await api.get("/matmed/matmeds/");
        this.medicamentos = data.filter(item => item.ativo && item.tipo.id === 1);
        // Garantir que todos os medicamentos sejam listados inicialmente
        this.medicamentosFiltrados = [...this.medicamentos];

        // Aplica o filtro caso já haja uma classe terapêutica selecionada
        // this.filtrarMedicamentosPorClasseTerapeutica();
        // // console.log('medicamento', this.medicamentos)
        // this.medicamentoCompelementar = data.filter(item => [53, 54].includes(item.id)) // relacionar os medicamdnro complementares
      } catch (error) {
        console.error("❌ Erro ao carregar medicamentos:", error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Erro: ${error}`);
      } finally {
        this.loadingMedicamento = false;
      }
    },
    async getMedicamentoPaciente() {
      this.loadingMedicamento = true;
      try {
        const idPaciente = this.medicamento.paciente.id
        // console.log(idPaciente)
        const { data } = await api.get(`/pacientes/medicamentos/?paciente=${idPaciente}`);
        this.medicamentosPaciente = data.filter(item => item.ativo );

      } catch (error) {
        console.error("❌ Erro ao carregar medicamentos:", error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Erro: ${error}`);
      } finally {
        this.loadingMedicamento = false;
      }
    },
    async getQuemFornece() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get("/pacientes/quem_fornece/");
        this.quemfornece = data.filter(item => item.ativo);
        // console.log("📌 Quem Fornece carregado:", this.quemfornece);
        // ✅ Definir automaticamente o ID 1 caso ele exista na lista
        const fornecimentoPadrao = this.quemfornece.find(item => item.id === 1);
        if (fornecimentoPadrao) {
          this.quemforneceSelecionado = fornecimentoPadrao.id;
        }
      } catch (error) {
        console.error("❌ Erro ao carregar quem fornece:", error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Erro: ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getDisponibilidade() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        this.disponibilidadeOptions = data.filter(item => [3, 2].includes(item.id));
        // console.log(this.disponibilidade)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getDiasSemana() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/uteis/dias_semana/`);
        this.diasSemana = data.filter(item => item.ativo);
        // console.log(this.diasSemana)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getFrequencia() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/atendimentos/frequencias/`);
        this.frequencia = data
          .filter(item => item.ativo)
          .sort((a, b) => a.nome.localeCompare(b.nome));
        // console.log(this.frequencia)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getViaAdministracao() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/matmed/via_administracao/`);
        this.viaAdministracao = data
          .filter(item => item.ativo)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getClasseTerapeutica() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/matmed/classe_terapeutica/`);
        this.classe_terapeutica = data.filter(item => item.ativo)
        // console.log(this.classe_terapeutica)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    async getHorarios() {
      this.loadingGeral = true;
      try {
        const { data } = await api.get(`/uteis/horarios/`);
        this.horarios = data.filter(item => item.ativo);
        // console.log(this.diasSemana)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.loadingGeral = false;
      }
    },
    getModifiedFields() {
      const modifiedFields = {};

      if (!this.medicamentoOriginal) return modifiedFields;

      Object.keys(this.medicamento).forEach(key => {
        const originalValue = this.medicamentoOriginal[key];
        const newValue = this.medicamento[key];

        if (JSON.stringify(originalValue) !== JSON.stringify(newValue)) {
          // Se for um objeto com chave 'id', enviamos apenas o ID
          if (newValue && typeof newValue === "object" && "id" in newValue) {
            modifiedFields[key] = newValue.id;
          }
          // Se for um array de objetos, extraímos os IDs
          else if (Array.isArray(newValue) && newValue.length > 0 && typeof newValue[0] === "object" && "id" in newValue[0]) {
            modifiedFields[key] = newValue.map(item => item.id);
          }
          // Caso contrário, enviamos o valor normalmente
          else {
            modifiedFields[key] = newValue;
          }
        }
      });
       // ✅ **Garantir que `data_requisicao` e `data_prevista_implantacao` sempre estejam no objeto**
      modifiedFields.data_requisicao = this.medicamento.data_requisicao ?? null;
      modifiedFields.data_prevista_implantacao = this.medicamento.data_prevista_implantacao ?? null;

      // ✅ Verifica se os horários foram alterados e adiciona ao objeto modificado
      if (
        JSON.stringify(this.horariosSelecionados) !== JSON.stringify(this.medicamentoOriginal.horarios_administracao?.map(h => h.id))
      ) 

      return modifiedFields;
    },
    async localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }

      // ⚠️ Se `ativo` for `false`, então `data_fim` NÃO pode estar vazia
      if (!this.medicamento.ativo && (!this.medicamento.data_fim || this.medicamento.data_fim.trim() === "")) {
        this.$toast.error("Se o medicamento estiver INATIVO, a Data Fim deve ser preenchida!");
        return;
      }

      this.isSubmitting = true;

      const modifiedFields = this.getModifiedFields();
      modifiedFields.id = this.medicamento.id; // Garante que o ID seja enviado
      modifiedFields.horario_administracao = this.horariosSelecionados;
      modifiedFields.quem_modificou = this.userData.apelido; // Mantemos quem modificou

      // ✅ **Garantir que os campos `data_requisicao` e `data_prevista_implantacao` sempre sejam enviados**
      if (this.medicamento.disponibilidade?.id === 3) {
        modifiedFields.data_requisicao = this.hojeData;
        modifiedFields.data_prevista_implantacao = this.hojeDataPrevista;
        modifiedFields.requisicao = true;
      } else if (this.medicamento.disponibilidade?.id === 2) {
        modifiedFields.data_requisicao = null;
        modifiedFields.data_prevista_implantacao = null;
        modifiedFields.requisicao = null;
      }

      try {
        // 🔍 **Verifica se o medicamento tem dependentes**
        const medicamentosDependentes = this.medicamentosPaciente.filter(
          med => med.id_medicamento_referencia_complementar == modifiedFields.id
        );

        // console.log("🔸 Medicamentos dependentes encontrados:", medicamentosDependentes);

        if (medicamentosDependentes.length > 0) {
          // 🔹 **Atualizar Medicamentos Dependentes Primeiro**
          const camposDependentes = {
            ativo: modifiedFields.ativo,
            data_fim: modifiedFields.data_fim,
            quem_modificou: this.userData.apelido
          };

          console.log("🔹 Atualizando medicamentos dependentes com os dados:", camposDependentes);

          // 🔄 Atualiza cada medicamento dependente
          for (const medicamento of medicamentosDependentes) {
            await api.put(`pacientes/medicamento/${medicamento.id}/`, {
              ...camposDependentes,
              id: medicamento.id
            });
          }

          // 🔹 **Agora atualiza o medicamento principal na página pai**
          await this.onSubmit(modifiedFields);
        } else {
          // 🛠 **Se não há dependentes, apenas atualiza o medicamento principal normalmente**
          await this.onSubmit(modifiedFields);
        }

        this.$toast.success("Alterações salvas com sucesso!");
        this.inicializar();
      } catch (error) {
        this.$toast.error(`Erro ao salvar: ${error}`);
      } finally {
        this.isSubmitting = false;
      }   
    },
    atualizarMedicamentoSelecionado() {
      if (!this.medicamento) return;
      
      const medicamentoCompleto = this.medicamentos.medicamento.find(med => med.id === this.medicamento);
      
      if (medicamentoCompleto) {
        this.medicamento = medicamentoCompleto; // Armazena o objeto completo
        this.atualizarViasAdministracao();
      }
    },
     atualizarViasAdministracao() {
      if (!this.medicamento || !this.medicamento.medicamento.via_administracao) {
        this.viaAdministracaoFiltrada = []; // Se não houver medicamento ou vias, limpa a lista
      } else {
        this.viaAdministracaoFiltrada = this.medicamento.medicamento.via_administracao.map(via => ({
          id: via.id,
          nome: via.nome,
          sigla: via.sigla
        }));
      }
    },
    prepararHorariosSelecionados() {
      if (this.medicamento.horarios_administracao) {
        this.horariosSelecionados = this.medicamento.horarios_administracao.split(";"); // Converte a string em array
      } else {
        this.horariosSelecionados = [];
      }
    },
    inicializar() {
      this.getMedicamento();
      this.getHorarios(); // Carrega a lista completa de horários
      this.getQuemFornece();
      this.getDisponibilidade();
      this.getDiasSemana();
      this.getFrequencia();
      this.getViaAdministracao();
      this.getClasseTerapeutica();
      this.sos = false;
      this.getMedicamentoPaciente()
      
      const hoje = new Date();
      const amanha = new Date();
      amanha.setDate(hoje.getDate() + 1);
      const formatarData = (data) => {
        return data.getFullYear() + '-' + 
          String(data.getMonth() + 1).padStart(2, '0') + '-' + 
          String(data.getDate()).padStart(2, '0');
      };
      
      this.hojeData = formatarData(hoje);
      this.dataInicioSelecioanda = formatarData(hoje);
      this.hojeDataPrevista = formatarData(amanha);
      this.quantidade_medicameneto_complementar = 1;
      
      // Configuração correta para array de objetos
      if (this.medicamento && this.medicamento.horario_administracao && 
          Array.isArray(this.medicamento.horario_administracao)) {
        // Extraímos apenas os IDs do array de objetos
        this.horariosSelecionados = this.medicamento.horario_administracao.map(horario => horario.id);
      } else {
        this.horariosSelecionados = [];
      }
      
      this.medicamento.ativo = Number(this.medicamento.ativo);
      if (this.medicamento) {
        this.medicamentoOriginal = JSON.parse(JSON.stringify(this.medicamento));
      }
      
    },
    marcarHorariosAutomaticamente() {
    if (!this.horaInicioSelecioanda) return; // Se não houver horário inicial, não faz nada
    
    // Converter a hora para número para facilitar cálculos
    const [hora, minuto] = this.horaInicioSelecioanda.split(':').map(Number);
    let horarios = [`${String(hora).padStart(2, "0")}:${String(minuto).padStart(2, "0")}`];

    // Verifica qual frequência foi selecionada
    const frequenciaSelecionada = this.frequencia.find(f => f.id === this.frequenciaSelecionada);
    if (!frequenciaSelecionada) return;

    // Apenas as frequências permitidas (3, 4, 15, 17 e 18) terão múltiplos horários
    const frequenciasPermitidas = [3, 4, 15, 16, 17, 18];

    if (frequenciasPermitidas.includes(frequenciaSelecionada.id)) {
      const qt = frequenciaSelecionada.qt; // Quantidade de administrações por dia
      let intervalo = Math.floor(24 / qt); // Define o intervalo de tempo entre administrações

      for (let i = 1; i < qt; i++) {
        let novaHora = (hora + (i * intervalo)) % 24; // Calcula a nova hora
        let novaHoraFormatada = `${String(novaHora).padStart(2, "0")}:${String(minuto).padStart(2, "0")}`;
        horarios.push(novaHoraFormatada);
      }
    }

    // Ordena os horários de forma crescente
    horarios.sort((a, b) => {
      const [horaA, minutoA] = a.split(":").map(Number);
      const [horaB, minutoB] = b.split(":").map(Number);
      return horaA - horaB || minutoA - minutoB;
    });

    // Atualiza os horários selecionados no campo "Selecionar Horários"
    this.horariosSelecionados = horarios;
    },
    marcarDiaDaSemanaAutomaticamente() {
      if (!this.frequenciaSelecionada) return;

      const frequenciasDiarias = [3, 4, 15, 16, 17, 18];
      const frequenciasQueMarcamUmDia = [12, 11, 10, 1, 14, 13];
      
      if (this.diasTratamentoSelecionado) {
          this.marcarDiasTratamento();
          return;
      }

      if (frequenciasDiarias.includes(this.frequenciaSelecionada)) {
          this.diasSemanaSelecioando = this.diasSemana.map(dia => dia.id);
          return;
      }

      if (frequenciasQueMarcamUmDia.includes(this.frequenciaSelecionada)) {
          const hoje = new Date();
          let diaSemanaAtual = hoje.getDay();
          if (diaSemanaAtual === 0 || diaSemanaAtual === 6) {
              diaSemanaAtual = 1;
          }
          this.diasSemanaSelecioando = [diaSemanaAtual];
          return;
      }
    },
    marcarDiasTratamento() {
      if (!this.diasTratamentoSelecionado) return;

      let quantidade = Math.min(this.diasTratamentoSelecionado, 7);
      let hoje = new Date();
      let diaAtual = hoje.getDay();
      diaAtual = diaAtual === 0 ? 7 : diaAtual; // Converte domingo de 0 para 7
      
      let diasSelecionados = [];
      let diasProcessados = 0;
      let proximoDia = diaAtual;
      
      // Continua adicionando dias até atingir a quantidade desejada
      while (diasProcessados < quantidade) {
          // Adiciona o dia atual se ainda não foi adicionado
          if (!diasSelecionados.includes(proximoDia)) {
              diasSelecionados.push(proximoDia);
              diasProcessados++;
          }
          
          // Move para o próximo dia
          proximoDia++;
          // Se passou de domingo (7), volta para segunda (1)
          if (proximoDia > 7) {
              proximoDia = 1;
          }
      }
      
      // Ordena os dias para garantir a sequência correta
      diasSelecionados.sort((a, b) => a - b);
      
      // Atualiza os dias selecionados
      this.diasSemanaSelecioando = diasSelecionados;

      // console.log("Dias selecionados:", diasSelecionados); // Para debug
    },
    marcarDiasTratamentoComFinaisDeSemana() {
      if (!this.diasTratamentoSelecionado) return;

      let quantidade = Math.min(this.diasTratamentoSelecionado, 7); // Limita a 7 dias, já que não podemos ter mais que isso
      let hoje = new Date();
      let diaAtual = hoje.getDay();
      diaAtual = diaAtual === 0 ? 7 : diaAtual; // Converte domingo de 0 para 7
      
      let diasSelecionados = [];
      
      // Primeiro, adicionamos o dia atual
      diasSelecionados.push(diaAtual);
      
      // Então, adicionamos os próximos dias necessários
      let diasRestantes = quantidade - 1;
      let proximoDia = diaAtual;
      
      while (diasRestantes > 0) {
          proximoDia++;
          if (proximoDia > 7) {
              proximoDia = 1; // Volta para segunda quando passar de domingo
          }
          
          if (!diasSelecionados.includes(proximoDia)) {
              diasSelecionados.push(proximoDia);
              diasRestantes--;
          }
      }
      
      // Ordena os dias
      diasSelecionados.sort((a, b) => a - b);
      
      // Atualiza os dias selecionados
      this.diasSemanaSelecioando = diasSelecionados;
    },  
    marcarDiasPorFrequencia(quantidadeDias) {
      let hoje = new Date();
      let diaAtual = hoje.getDay();
      diaAtual = diaAtual === 0 ? 7 : diaAtual; // Converte domingo de 0 para 7
      
      let diasDisponiveis = [1, 2, 3, 4, 5]; // Apenas dias úteis (seg a sex)
      let diasSelecionados = [];
      
      // Remove o dia atual da lista de dias disponíveis se for dia útil
      if (diaAtual <= 5) {
          diasDisponiveis = diasDisponiveis.filter(d => d !== diaAtual);
      }
      
      // Adiciona o dia atual se for dia útil
      if (diaAtual <= 5) {
          diasSelecionados.push(diaAtual);
      }
      
      // Seleciona os dias restantes necessários
      while (diasSelecionados.length < quantidadeDias && diasDisponiveis.length > 0) {
          // Calcula o intervalo ideal entre os dias
          const intervalo = Math.floor(5 / quantidadeDias);
          
          // Encontra o próximo dia adequado
          let proximoDia = diaAtual + intervalo;
          while (proximoDia > 5) proximoDia -= 5;
          
          // Se o dia já estiver selecionado, tenta o próximo
          while (diasSelecionados.includes(proximoDia) && diasDisponiveis.length > 0) {
              proximoDia = proximoDia + 1 > 5 ? 1 : proximoDia + 1;
          }
          
          if (!diasSelecionados.includes(proximoDia)) {
              diasSelecionados.push(proximoDia);
              diasDisponiveis = diasDisponiveis.filter(d => d !== proximoDia);
          }
          
          diaAtual = proximoDia;
      }
      
      // Ordena os dias selecionados
      diasSelecionados.sort((a, b) => a - b);
      
      // Atualiza os dias selecionados
      this.diasSemanaSelecioando = diasSelecionados;
      
      // console.log("Dias selecionados para frequência:", diasSelecionados);
    },        
    removeViaAdministracao(id) {
      // Verifica se é um array antes de tentar filtrar
      if (Array.isArray(this.medicamento.dias_semana)) {
        this.medicamento.dias_semana = this.medicamento.dias_semana.filter(dia => dia !== id);
      }
    },
    lista() {
      return this.userData || {}
    },
    
    toggleExpand() {
      this.expandHorarios = !this.expandHorarios;
    },
    removeHorario(id) {
      this.horariosSelecionados = this.horariosSelecionados.filter(h => h !== id);
    },
    getHorarioNome(id) {
      const horario = this.horarios.find(h => h.id === id);
      return horario ? horario.nome : 'Desconhecido';
    },

  },
  components: { ConfirmButton },
  computed: {
     ...mapGetters(['companyData', 'userData']),
    viaAdministracaoText() {
      return (item) => {
          return `${item.nome} - ${item.sigla} `;
      }
    },
    prescricaoText() {
      return this.medicamento && this.medicamento.apresentacao_prescricao
        ? `${this.medicamento.apresentacao_prescricao.nome} - ${this.medicamento.apresentacao_prescricao.abreviatura}`
        : "Sem prescrição definida";
    },
    exibirObsViaSonda() {
      return (
        this.medicamento &&
        this.medicamento.via_sonda &&
        this.medicamento.via_sonda.nome?.toLowerCase() === "sim" &&
        this.medicamento.obs_via_sonda
      );
    },
    altorisco() {
      return (
        this.medicamento &&
        this.medicamento.alto_risco === true
      );
    },
    interacaoMedicamento() {
      return (
        this.medicamento &&
        this.medicamento.interacao === true
      );
    },
    padrao() {
      return this.medicamento && this.medicamento.padrao === true;
    },
    // ✅ Computed para filtrar as frequências dependendo se Dias de Tratamento está preenchido ou não
    frequenciasFiltradas() {
        const frequenciasPermitidas = [3, 4, 15, 16, 17]; // ✅ IDs permitidos se dias de tratamento estiverem preenchidos

        if (this.diasTratamentoSelecionado || this.quantidadeTratamentoSelecionado) {
            // Retorna apenas as frequências permitidas
            return this.frequencia.filter(f => frequenciasPermitidas.includes(f.id));
        }
        return this.frequencia; // Se não houver "Dias de Tratamento", mostra todas as frequências
    },
    horariosSelecionadosText() {
        if (!this.horariosSelecionados.length || !this.horarios || this.horarios.length === 0) {
          return "Selecione os horários";
        }

        return this.horariosSelecionados
          .map(id => {
            const horario = this.horarios.find(h => h.id === id);
            return horario ? horario.nome : "Desconhecido";
          })
          .join(", ");
      },
    frequenciaSelecionada: {
      get() {
        return this.medicamento.frequencia ? this.medicamento.frequencia.id : null;
      },
      set(value) {
        if (!this.medicamento.frequencia) {
          this.$set(this.medicamento, 'frequencia', {}); // Garante que não seja null
        }
        this.medicamento.frequencia.id = value;
      }
    },
    viasPermitidas() {
      return this.medicamento?.medicamento?.via_administracao || [];
    },
  },
  watch: {
      // Se o usuário preencher a data_fim, define ativo como false
    // "medicamento.data_fim"(newValue, oldValue) {
    //   if (newValue && oldValue !== newValue) {
    //     this.medicamento.ativo = false;
    //   }
    // },
    
    // Se o usuário definir ativo como false, preenche a data_fim com a data de hoje (somente se não tiver um valor)
    "medicamento.ativo"(newValue, oldValue) {
      if (newValue === false && oldValue !== newValue) {
        if (!this.medicamento.data_fim) { // ⚠️ Só preenche se estiver vazia
          const hoje = new Date();
          this.medicamento.data_fim = hoje.toISOString().split("T")[0];
        } 
      }
    },
    'medicamento.horario_administracao': function(newValue) {
      if (newValue && Array.isArray(newValue)) {
        this.horariosSelecionados = newValue.map(horario => horario.id);
      } else {
        this.horariosSelecionados = [];
      }
    },
    "medicamento.via_administracao": {
      handler(newValue) {
        if (!newValue) {
          // Se não houver via selecionada, define a primeira disponível como padrão
          if (this.viasPermitidas.length > 0) {
            this.medicamento.via_administracao = this.viasPermitidas[0];
          }
        }
      },
      immediate: true, // Executa essa verificação assim que o componente é montado
    }
  },
  mounted() {
    this.inicializar()
  },
};
</script>
