<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="6">
        <p><b>Observações / Orientações para Prescrição / Instrucao:</b> {{ item.obs || 'Nenhuma' }}</p>
        <p><b>Quem Adicionou esse Material:</b> {{ item.quem_prescreveu || 'Não Informado' }}</p>
        <p v-if="item.quem_modificou"><b>Quem Modificou essa Material:</b> {{ item.quem_modificou || 'Não Informado' }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: "IndexRevisado",
  props: {
    item: Object,
  },
  data: () => ({
    isPageLoading: true,
  }),
  methods: {
  },
  mounted() {
    this.isPageLoading = false;
  },
};
</script>

<style scoped>
.text {
  color: red;
}
</style>
