<template>
  <v-dialog v-model="showModal" max-width="600px"> 
    <v-card>
      <v-card-title>
        <span class="blod_color">Selecionar a Data Início e Data Fim para gerar o PDF 2</span>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col >
              <v-text-field v-model="data_inicio" label="data Início" required type="date" outlined dense ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="data_fim" label="Data Fim" required type="date" outlined dense ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="cancelModal">Cancelar</v-btn>
        <v-btn color="green" text @click="confirmModal" :loading="loading">Confirmar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
  import api from "@/http";
  import jsPDF from "jspdf";
  import "jspdf-autotable";
  import { mapGetters } from 'vuex';
  import UtilsPDF from '../../../service/utilsPDF';
  import logo from "../../../assets/logo.png";
  import ona from "../../../assets/ONA_Nivel_3.png";
  import gptw from "../../../assets/GPTW_2023-2024.png";
  import moment from "moment";


  export default {
    name: "IndicadorOperadora",
    data() {
      return {
        loading: false,
        data_inicio: "",
        data_fim: "",
        material: [],
        paciente: [],
        };
    },
    props: {
      pacienteId: Number,
      showModal: {
        type: Boolean,
        required: true
      },
      selectedMedicamento: Array,
    },
    methods: {
      openModal() {
        if (this.material.length === 0) {
          this.loading = true; // Mostra o loading enquanto carrega
        }
      },
      cancelModal() {
        this.$emit('update:showModal', false); // Emite o evento para fechar o modal
      },
      confirmModal() {
        if (!this.data_inicio || !this.data_fim) {
          this.$toast.error("Por favor, preencha todos os campos.");
          return;
        }
        this.loading = true
        this.getMaterial()
        this.getPaciente()
        this.loading = false
        this.$emit('update:showModal', false); // Fecha o modal

        this.getPDF(); // Gera o PDF
      },
      async getPDF() {
        
        // Se não houver nenhum material, exibe mensagem de erro e não gera o PDF
        if (this.material.length === 0) {
          this.$toast.error("Sem dados para gerar o relatório.");
          return;
        }
        this.loading = true;
        this.$toast.success(`O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`);
        try {
          const doc = this.generatePDF();
          
          // Salva o PDF diretamente após a geração
          const filename = `Checagem_de_Material_.pdf`;
          doc.save(filename); // Salva o PDF no navegador
          this.loading = false;
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados:", error);
          this.loading = false;
        }
      },
      drawHeader(doc) {
        try {
          doc.addImage(logo, 10, 6, 22, 20);
          doc.addImage(ona, 250, 8, 15, 15);
          doc.addImage(gptw, 270, 8, 15, 15);
        } catch (error) {
          this.$toast.error("Falha ao adicionar a imagem ao PDF:", error);
        }
        const data = this.paciente[0];
        
        const lineHeight = 6;
        const xFixedPosition = 10;
        let initialYPosition = 35;
        let yPosition = 45;

        doc.setFont("helvetica", "normal");
        doc.setDrawColor(57, 106, 159);
        doc.setLineWidth(1);
        doc.line(2, 2, 295, 2); // linha azul superior

        doc.setLineWidth(0.5); // largura da borda
        doc.roundedRect(5, 30, 250, 20, 3, 3, "S");

        moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

        const currentDate = new Date();
        const hour = moment(currentDate).format("HH:mm");

        doc.setFontSize(8);
        const marginRight = 5; // Defina a margem desejada do lado direito (5px)
        const pageWidth = doc.internal.pageSize.getWidth(); // Obtenha a largura total da página
        const textWidth = doc.getTextWidth(`Gerado por: ${this.userData.apelido}`); // Calcule a largura do texto "Gerado por: userData.apelido"
        const xPosition = pageWidth - textWidth - marginRight; // Calcule a posição x para alinhar o texto à direita
        doc.text(`Gerado por: ${this.userData.apelido}`, xPosition, 28);
        doc.text("Data e Hora da Impressão:", pageWidth - doc.getTextWidth("Data e Hora da Impressão:") - marginRight, 32);
        doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, pageWidth - doc.getTextWidth(`${UtilsPDF.formatDate(currentDate)} às ${hour}`) - marginRight, 36);

        if (data && data?.data_inicio && data?.data_fim) {
          doc.setFontSize(11);
          doc.text(`Período de: ${UtilsPDF.formatDate(data.data_inicio)} até: ${UtilsPDF.formatDate(data.data_fim)}`, 71, 32);
        }

      // **Cálculo da idade do paciente**
        let idadePaciente = "";

        if (data && data.data_nascimento) {
            try {
                const birthDate = moment(data.data_nascimento, "YYYY-MM-DD"); // Converte a data de nascimento para moment
                const currentDate = moment(); // Garante que a data atual também seja um objeto moment
                idadePaciente = currentDate.diff(birthDate, 'years'); // Calcula idade em anos
            } catch (error) {
                console.error("Erro ao calcular a idade:", error);
                idadePaciente = "Erro";
            }
        }
        doc.setFontSize(12);
        doc.text(`Paciente: ${data && data.nome ? data.nome : ''}     Sexo Biológico: ${data && data.sexo_biologico ? data.sexo_biologico : ''}`, xFixedPosition, initialYPosition);

        yPosition = initialYPosition + lineHeight
        const dataNascimento = data && data.data_nascimento ? `Data Nascimento: ${UtilsPDF.formatDate(data.data_nascimento)} - ${idadePaciente} anos` : 'Data Nascimento: ';
        doc.text(dataNascimento, 250, initialYPosition, { align: "right" });

        doc.text(`Operadora: ${data && data.operadora ? data.operadora.nome : ''}   -   Modalidade: ${data && data.plano_terapeutico ? data.plano_terapeutico[0].sessao__modalidade__nome : ''}`, xFixedPosition, yPosition);
        doc.text(`Nº da Carteira: ${data && data.nr_carteirinha ? data.nr_carteirinha : ''}`, 250, yPosition, { align: "right", });

        yPosition += lineHeight
        doc.text(`Alergia: ${data && data.alergia ? data.alergia : ''}`, xFixedPosition, yPosition);

        const maxWidth = 250;
        const spaceBetweenTexts = 10; // Espaço entre modalidadeText e CID

        const modalidadeText = `Modalidade: ${data && data.modalidade ? data.modalidade : ''}`;
        const modalidadeTextWidth = doc.getTextWidth(modalidadeText);

        if (data && data.cid && data.cid.descricao) {
          const cid = data.cid.nome || '';
          const cidDescricao = data.cid.descricao || '';
          const fullText = `CID: ${cid} - ${cidDescricao}`;

          const renderTextLine = (doc, text, x, y, align = 'left') => {
            doc.setFont("helvetica", "normal");
            doc.text(text, x, y, { align: align });
          };

          const remainingWidth = maxWidth - modalidadeTextWidth - spaceBetweenTexts;
          const splittedText = doc.splitTextToSize(fullText, remainingWidth);

          splittedText.forEach((line, i) => {
            const xPos = i === 0 ? 250 : 10;
            const align = i === 0 ? 'right' : 'left';
            const yPos = yPosition + (lineHeight * i);
            renderTextLine(doc, line, xPos, yPos, align);
          });

        yPosition += (lineHeight * splittedText.length);

      } else {
        doc.setFont("helvetica", "normal");
        doc.text(`CID: `, 200, yPosition, { align: "right" });
      }

        doc.setFont("helvetica", "bold");
        doc.setFontSize(12);
        doc.text("SÃO LUÍS HOME CARE", 140, 8, { align: "center" });
        doc.text("MATERIAL DE ENFERMAGEM", 138, 15, { align: "center" });
        doc.text(`Vigência: ${UtilsPDF.formatDate(this.data_inicio)} até ${UtilsPDF.formatDate(this.data_fim)}.`, 138, 23,{ align: "center" });
        doc.setFont("helvetica", "normal");
        doc.setFontSize(9);
        doc.text(`Enfermeiro(a) Responsável: ${ data && data.enfermeiro ? data.enfermeiro.nome : 'Não Cadastrado'} - COREN: ${ data && data.enfermeiro ? data.medico.nconselho : 'Não Cadastrado'}`, 138, 28,{ align: "center" });
      },
      
      generatePDF() {
        // Cria o documento PDF no formato paisagem (A4)
        const doc = new jsPDF({ orientation: 'landscape', unit: 'mm', format: 'a4' });

        // Define a posição inicial para o conteúdo
        let currentY = 52;

        // Desenha o cabeçalho na primeira página
        this.drawHeader(doc);

        // Configurações gerais da fonte e tamanho para a tabela
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");

        // --- INÍCIO DA CONSTRUÇÃO DA TABELA ---
        const maxDateColumns = 8; // Número máximo de colunas de datas por página
        const start = moment(this.data_inicio, "YYYY-MM-DD");
        const end = moment(this.data_fim, "YYYY-MM-DD");

        let allDates = [];
        for (let m = start.clone(); m.isSameOrBefore(end); m.add(1, 'days')) {
          allDates.push(m.format("DD/MM/YYYY"));
        }

        // Divide o array de datas em fatias de no máximo `maxDateColumns`
        const dateChunks = [];
        for (let i = 0; i < allDates.length; i += maxDateColumns) {
          dateChunks.push(allDates.slice(i, i + maxDateColumns));
        }

        // **Verificação para evitar erro caso this.aprazamento esteja vazio**
        if (!this.material || this.material.length === 0) {
          this.$toast.error("Nenhum dado de aprazamento disponível para gerar o PDF.");
          return doc;
        }

        // Percorre cada fatia de datas e gera a tabela correspondente
        dateChunks.forEach((dateChunk, chunkIndex) => {
          // Cabeçalho da tabela
          const tableHeader = [["Descrição", "Freq.", ...dateChunk]];

          // Cria o corpo da tabela
          const tableBody = this.material.map(item => {
            // Verifica se os campos necessários existem antes de acessá-los
            const descricao = item.medicamento?.nome_substancia 
              ? `${item.medicamento?.nome_substancia} - ${item.medicamento.apresentacao_prescricao.nome} ${item.sos ? ' - "SE NECESSÁRIO"':""}\n Resp. pelo Fornec.: ${item.quem_fornece.nome}`
              : "Sem descrição";

            const frequencia = item.frequencia?.nome || "";

            // Define a data inicial e final do item
            const itemStart = item.data_inicio ? moment(item.data_inicio, "YYYY-MM-DD") : null;
            const itemEnd = item.data_fim ? moment(item.data_fim, "YYYY-MM-DD") : end; // Se não tiver data_fim, usamos a última data do relatório

            // Cria células para cada coluna de data
            const rowCells = dateChunk.map(date => {
              const dateMoment = moment(date, "DD/MM/YYYY");

              // Verifica se a data está dentro do intervalo do item
              if (itemStart && dateMoment.isSameOrAfter(itemStart) && dateMoment.isSameOrBefore(itemEnd)) {
                if (item.horario_administracao) {
                  // Adiciona horários de administração, separados por "; "
                  return item.horario_administracao.map(h => h.nome).join("; ");
                }
              }

              return ""; // Mantém a célula vazia se a data não estiver no intervalo
            });

            return [descricao, frequencia, ...rowCells];
          });

          // Configuração de estilos para a tabela
          const columnStyles = {
            0: { cellWidth: 80 }, // Largura da coluna "Descrição"
            1: { cellWidth: 30 }, // Largura da coluna "Freq."
          };

          // Renderiza a tabela no PDF
          doc.autoTable({
            head: tableHeader,
            body: tableBody,
            startY: currentY,
            theme: "grid",
            styles: {
              fontSize: 10,
              cellPadding: 2,
              halign: "center",
              valign: "middle"
            },
            headStyles: {
              fillColor: [57, 106, 159], // Fundo azul para o cabeçalho
              textColor: [255, 255, 255], // Texto branco
              fontStyle: "bold",
              halign: "center",
              valign: "middle"
            },
            columnStyles: columnStyles,
            margin: { left: 5, right: 5 },
          });

          // Atualiza a posição Y para a próxima tabela (caso haja mais fatias de datas)
          currentY = doc.lastAutoTable.finalY + 10;

          // Se houver mais fatias de datas, adiciona uma nova página e redesenha o cabeçalho
          if (chunkIndex < dateChunks.length - 1) {
            doc.addPage();
            this.drawHeader(doc);
            currentY = 52;
          }
        });

        // --- FIM DA CONSTRUÇÃO DA TABELA ---
       // 🔹 Adiciona o carimbo do enfermeiro no final da última página, sem sobrescrever a tabela
        const item = this.paciente[0];

        if (item && item.enfermeiro && item.enfermeiro.carimbo) {
            let carimbo = item.enfermeiro.carimbo;
            const params = item.enfermeiro.carimbo.split('://');
            if (params[0] === 'http' && params[1] !== 'localhost') {
                carimbo = carimbo.replace('http', 'https');
            }

            try {
                const pageHeight = doc.internal.pageSize.getHeight();
                let lastTableY = doc.lastAutoTable.finalY || 0;
                const marginBottom = 30; // Margem de segurança inferior
                const requiredSpace = 40; // Espaço necessário para a assinatura
                const spaceLeft = pageHeight - lastTableY - marginBottom; // Calcula o espaço disponível abaixo da tabela
              
                // Se não houver espaço suficiente, adiciona uma nova página
                if (spaceLeft < requiredSpace) {
                    doc.addPage();
                    lastTableY = 20; // Reinicia a posição na nova página
                }

                const xPosition = doc.internal.pageSize.getWidth() - 55; // Posição X (direita da página)
                const yPosition = lastTableY + 20; // Posição Y abaixo da última tabela

                doc.setFont("helvetica", "bold");
                // doc.text("Enfermeiro Responsável:", xPosition - 15, yPosition - 5);
                doc.addImage(carimbo, "PNG", xPosition, yPosition, 50, 25);

            } catch (error) {
                this.$toast.error("Erro ao carregar a imagem do carimbo:", error);
            }
        }


  // Adiciona numeração de páginas (executado após a construção do PDF)
  UtilsPDF.addPageNumbersPrescricao(doc);

  return doc;
}
,
      async download(dataurl, filename) {
        const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl
        const res = await fetch(httpsurl)
        const buffer = await res.blob()
        const url = URL.createObjectURL(buffer)
        const link = document.createElement("a");
        link.download = filename;
        link.href = url;
        link.click();
      },
      // async getMaterial() {
      //   try {
      //     const { data } = await api.get(`/pacientes/medicamentos/?material&paciente=${this.pacienteId}`);
      //     this.material = data
      //       .filter(item => item.ativo) // Filtra apenas os itens ativos
      //       .sort((a, b) => Number(a.sos) - Number(b.sos)); // Ordena para que sos=true fique por último
            
      //     // console.log('this.material', this.material)
      //   } catch (error) {
      //     this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      //   }
      // },
      async getMaterial() {
        try {
          let medicamentos = [];

          if (this.selectedMedicamento && this.selectedMedicamento.length > 0) {
            // 🔹 Se a prop `selectedMedicamento` foi passada, usamos ela diretamente
            medicamentos = this.selectedMedicamento;
          } else {
            // 🔹 Caso contrário, buscamos os medicamentos via API
            const response = await api.get(
              `/pacientes/medicamentos/?dieta&paciente=${this.pacienteId}`
            );
            medicamentos = response.data;
          }

          // 🔹 Filtramos apenas os ativos e ordenamos (sos=true por último)
          this.material = medicamentos
            .filter((item) => item.ativo)
            .sort((a, b) => Number(a.sos) - Number(b.sos));

        } catch (error) {
          this.$toast.error(`Erro ao carregar os medicamentos: ${error}`);
        }
      },
      async getPaciente() {
        try {
          const { data } = await api.get(  `/pacientes/detalhes_relatorio/?paciente=${this.pacienteId}`);
          this.paciente = data
          // console.log('paciente->', this.paciente)
        } catch (error) {
          this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
        }
      },
      lista() {
        return this.userData || {}
      },
    },
    computed:{ ...mapGetters(['companyData', 'userData']),},
    mounted(){
      this.getMaterial()
      this.getPaciente()
    },
    watch: {
      showModal(val) {
        if (val) {
          this.openModal(); // Abre o modal quando showModal for true
          this.getMaterial()
          this.getPaciente()
        }
      }
    },
  };
  </script>
